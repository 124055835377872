export function loginUserUrl(): string {
  return 'login/';
}

export function getPatientListUrl(): string {
  return 'patients/';
}

export function createPatientUrl(): string {
  return 'patients/';
}

export function getGenderUrl(): string {
  return 'genders/';
}
export function getDoctorUrl(id: number): string {
  return `doctors/${id}/`;
}

export function getDoctorListUrl(): string {
  return 'doctors/';
}

export function getCountryUrl(): string {
  return 'https://countriesnow.space/api/v0.1/countries';
}

export function getStateUrl(): string {
  return 'https://countriesnow.space/api/v0.1/countries/states';
}

export function getCitiesListUrl(): string {
  return 'https://countriesnow.space/api/v0.1/countries/state/cities';
}

export function getPatientUrl(id: number): string {
  return `patients/${id}/`;
}

export function getMyProfileUrl(): string {
  return 'me/profile';
}

export function getCycleListUrl(patientId: string): string {
  return `cycles/?patient_id=${patientId}`;
}

export function cycleReportUrl(id: number): string {
  return `cycles/${id}/generate-report/`;
}
export function getEmbryoListUrl(cycleId: number): string {
  return `embryos/?cycle_id=${cycleId}`;
}

export function deleteEmbryoUrl(id: number): string {
  return `embryos/${id}/`;
}

export function getCycleUrl(id: number): string {
  return `cycles/${id}/`;
}

export function createCycleUrl(): string {
  return 'cycles/';
}

export function getCreateEmbryoUrl(): string {
  return 'embryos/';
}

export function createTicketUrl(): string {
  return 'tickets/';
}

export function getTicketListUrl(department: string, searchTerm: string): string {
  return `tickets/?department__icontains=${department}&title__icontains=${searchTerm}`;
}

export function getTicketUrl(id: number): string {
  return `tickets/${id}/`;
}

export function uploadConsentFormUrl(patientId: number): string {
  return `/patients/${patientId}/upload-consent-form/`;
}

export function deleteConsentFormUrl(patientId: number): string {
  return `/patients/${patientId}/delete-consent-form/`;
}

