import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Checkbox,
  FormHelperText, Chip,
  DialogContentText,

} from '@mui/material';
import { createCycle, getCycleList, updateCycle } from '../../services/apiService';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '../alertProvider';
import { useLoader } from '../loaderProvider/loader';
import { CreateCycle, CreateCycleForm, Cycle, UterinePreparationEnum } from '../../types';
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik';
import styles from './cycledetails.module.css';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '../../assets/images/arrowDown.png';
import CloseIcon from '@mui/icons-material/Close';
import SuccessIcon from '../../assets/images/green-tick.png';
import CycleIcon from '../../assets/images/cycleCheck.svg';

const stimulationOptions = [
  { value: 'AGONIST_DAILY', label: 'Agonist Daily' },
  { value: 'AGONIST_DEPOT', label: 'Agonist Depot' },
  { value: 'ANTAGONIST', label: 'Antagonist' },
  { value: 'DOWN_REGULATED_FET', label: 'Down Regulated FET' },
  { value: 'FLARE', label: 'Flare' },
  { value: 'GNRH_LONG_PROTOCOL', label: 'GnRh Long Protocol' },
  { value: 'MEDICATED_FET', label: 'Medicated FET' },
  { value: 'MINIMAL_STIMULATION', label: 'Minimal Stimulation' },
  { value: 'NATURAL_CYCLE_FET', label: 'Natural Cycle FET' },
  { value: 'OTHER', label: 'Other' },
  { value: 'RECIPIENT_ANTAGONIST', label: 'Recipient Antagonist' },
  { value: 'RECIPIENT_DEPOT', label: 'Recipient Depot' },
  { value: 'SOFT_PROTOCOL', label: 'Soft Protocol' },
  { value: 'ULTRASHORT', label: 'Ultrashort' },
];

const artIndicationOptions = [
  { value: 'ABNORMAL_SPERM_PARAMETERS', label: 'Abnormal Sperm Parameters' },
  { value: 'AZOOSPERMIA_NON_OBSTRUCTIVE', label: 'Azoospermia Non Obstructive' },
  { value: 'AZOOSPERMIA_OBSTRUCTIVE', label: 'Azoospermia Obstructive' },
  { value: 'COMMUNICATING', label: 'Communicating' },
  { value: 'DIMINISHED_OVARIAN_RESERVE', label: 'Diminished Ovarian Reserve' },
  { value: 'ENDOMETRIOSIS', label: 'Endometriosis' },
  { value: 'LOW_MORPHOLOGY', label: 'Low Morphology' },
  { value: 'LOW_MOTILITY', label: 'Low Motility' },
  { value: 'MALE_FACTOR', label: 'Male Factor' },
  { value: 'MULTIPLE_FACTORS', label: 'Multiple Factors' },
  { value: 'OLIGOSPERMIA_MODERATE', label: 'Oligospermia Moderate' },
  { value: 'OLIGOSPERMIA_SEVERE', label: 'Oligospermia Severe' },
  { value: 'OTHER_FACTOR', label: 'Other Factor' },
  { value: 'OTHER_MALE_FACTOR', label: 'Other Male Factor' },
  { value: 'OVULATORY_DYSFUNCTION', label: 'Ovulatory Dysfunction' },
  { value: 'TUBAL_FACTOR', label: 'Tubal Factor' },
  { value: 'UNEXPLAINED_INFERTILITY', label: 'Unexplained Infertility' },
  { value: 'UTERINE_FACTOR', label: 'Uterine Factor' },
  { value: 'VERY_SEVERE_MALE_FACTOR', label: 'Very Severe Male Factor' },
];

const uterinePreparationOptions = [
  { value: 'FET-A', label: 'Fet-A' },
  { value: 'FET-M', label: 'Fet-M' },
  { value: 'PROGESTERONE', label: 'Progesterone' },
  { value: 'CRINONE', label: 'Crinone' },
  { value: 'ENDOMETRIN', label: 'Endometrin' },
];

const triggerTypeOptions = [
  { value: 'HCG', label: 'HCG' },
  { value: 'LEUPRON', label: 'Leupron' },
  { value: 'LEUPRON+HCG', label: 'Leupron+Hcg' },
];

const patternOptions = [
  { value: 'A_TRIPLE_LINE_PATTERN', label: 'A : Triple Line Pattern' },
  { value: 'B_INTERMEDIATE_ISOECHOGENIC', label: 'B : Intermediate Isoechogenic' },
  { value: 'C_HOMOGENEOUS_HYPERECHOGENIC', label: 'C : Homogeneous, Hyperechogenic' },
];

const treatmentOptions = [
  { value: 'ICSI', label: 'ICSI' },
  { value: 'IVF', label: 'IVF' },
];

const incubatorOptions = [
  { value: 'HERA_CELL', label: 'Hera Cell' },
  { value: 'IVFTECH', label: 'IVFtech' },
  { value: 'MEDGYN', label: 'MedGyn' },
  { value: 'MINC_K_SYSTEMS', label: 'MINC/K Systems' },
  { value: 'OOSAFE', label: 'Oosafe' },
  { value: 'VITROLIFE', label: 'Vitrolife' },
];

const cultureMediaOptions = [
  { value: 'IRVINE_SCIENTIFIC', label: 'Irvine Scientific' },
  { value: 'KITAZATO', label: 'Kitazato' },
  { value: 'SWEMED', label: 'Swemed' },
];

const oocyteEggsOptions = [
  { value: 'FRESH_EGGS', label: 'Fresh Eggs' },
  { value: 'THAWED_EGGS', label: 'Thawed Eggs' },
];

const spermOptions = [
  { value: 'FRESH_SPERM', label: 'Fresh Sperm' },
  { value: 'THAWED_SPERM', label: 'Thawed Sperm' },
];

const spermDonorSourceOptions = [
  { value: 'CRYOS', label: 'Cryos' },
  { value: 'IN_HOUSE', label: 'In-House' },
  { value: 'SPERM_BANK', label: 'Sperm Bank' },
  { value: 'WORLD_BANK', label: 'World Bank' },
];

const eggDonorSourceOptions = [
  { value: 'CRYOS', label: 'Cryos' },
  { value: 'EGG_BANK', label: 'Egg Bank' },
  { value: 'IN_HOUSE', label: 'In-House' },
  { value: 'WORLD_BANK', label: 'World Bank' },
];

const morphologyOptions = [
  { value: 'NORMAL', label: 'Normal' },
  { value: 'ABNORMAL', label: 'Abnormal' },
];


const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0]; // Format: YYYY-MM-DD
};

const getInitialCycleState = () => {
  return {
    lastMenstrualDate: '',
    retrievalDate: '',
    startDate: getCurrentDate(),
    isActive: false,
  };
};
const CycleDetails: React.FC = () => {
  const [error, setError] = useState<string | undefined>(undefined);
  const { id } = useParams<{ id: string }>();
  const { addAlert } = useAlert();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useLoader();
  const [cycleList, setCycleList] = useState<Cycle[]>([]);
  const [selectedCycle, setSelectedCycle] = useState<Cycle | null>(null);
  const [formDisabled, setFormDisabled] = useState(false);
  const [openCreateCycleDialogOpen, setCreateCycleDialogOpen] = useState(false);
  const [createCycleState, setCreateCycleState] = useState<CreateCycleForm>(getInitialCycleState());

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaveAction, setIsSaveAction] = useState(false);

  const handleCycleChange = (event: SelectChangeEvent<number>) => {
    const selectedId = event.target.value;
    const cycle = cycleList.find((cycle) => cycle.id === selectedId) || null;
    setSelectedCycle(cycle);
    setFormDisabled(true);

    // Update createCycleState based on the selected cycle
    if (cycle) {
      setCreateCycleState({
        ...createCycleState,
        lastMenstrualDate: cycle.last_menstrual_date,
        retrievalDate: cycle.retrieval_date,
        startDate: cycle.start_date,
        isActive: cycle.is_active ?? true,
      });
    }
  };


  const handleDialogOpen = () => {
    setCreateCycleState({
      ...getInitialCycleState(),
      isActive: true,
    });
    setCreateCycleDialogOpen(true);
  };

  const handleDialogClose = () => {
    setCreateCycleDialogOpen(false);
  };


  const handleCreateCycleSubmit = () => {
    const cycleForm: CreateCycle = {
      last_menstrual_date: createCycleState.lastMenstrualDate,
      is_active: createCycleState.isActive,
      retrieval_date: createCycleState.retrievalDate,
      start_date: createCycleState.startDate,
      patient_id: Number(id),
    };
    showLoader();
    createCycle(cycleForm)
      .then(() => {
        addAlert('Cycle created successfully', 'success');
        if (id) {
          getCycleList(id).then((response) => {
            setCycleList(response.objects);

            // Set the newly created cycle as the selected cycle
            const activeCycle = response.objects.find(cycle => cycle.is_active);
            if (activeCycle) {
              setSelectedCycle(activeCycle);
            }
          });
          setFormDisabled(false);
        }
      })
      .catch((err) => {
        addAlert('Failed to create cycle.', 'error');
        /* eslint-disable-next-line no-console */
        console.error(err);
      })
      .finally(() => {
        hideLoader();
        setCreateCycleState(getInitialCycleState());
        setCreateCycleDialogOpen(false);
      });
  };


  useEffect(() => {
    if (id) {
      showLoader();
      getCycleList(id)
        .then((response) => {
          setCycleList(response.objects);

          const activeCycle = response.objects.find(cycle => cycle.is_active);
          if (activeCycle) {
            setSelectedCycle(activeCycle);
            setFormDisabled(true);
          }
        })
        .catch((err) => {
          setError('Failed to fetch patients cycle list.');
          addAlert('Failed to fetch patients cycle list.', 'error');
          /* eslint-disable-next-line no-console */
          console.error(err);
        })
        .finally(() => {
          hideLoader();
        });
    }
    // eslint-disable-next-line
  }, [id]);

  if (error) {
    return <div>{error}</div>;
  }


  const handleEdit = () => {
    setFormDisabled(false);
  };
  // Define initial values for the form
  const initialValues: Cycle = {
    id: 0,
    patient_id: 0,
    start_date: '',
    retrieval_date: '',
    last_menstrual_date: '',
    post_era: false,
    receptive: false,
    embryos:[],
  };



  const handleSubmit = async (values: Cycle, { setSubmitting, setErrors, resetForm }: FormikHelpers<Cycle>) => {
    if (!selectedCycle) {
      addAlert('Please select a cycle to update.', 'error');
      return;
    }
    showLoader();
    values.patient_id = Number(id);

    updateCycle(selectedCycle.id, values)
      .then((response: Cycle) => {
        setCycleList((prevCycleList) => prevCycleList.map((cycle) =>
          cycle.id === response.id ? response : cycle));
        setSelectedCycle(response);
        resetForm();
        addAlert('Cycle Information updated successfully', 'success');
        setFormDisabled(true);
      })
      .catch((error) => {
        if (error.response?.data?.type === 'validation_error') {
          const apiErrors = error.response.data.errors;
          const formikErrors: FormikErrors<Cycle> = {};

          apiErrors.forEach((error: { attr: keyof Cycle; detail: string }) => {
            formikErrors[error.attr] = error.detail;
          });

          setErrors(formikErrors);
        } else {
          addAlert('An error occurred while updating the cycle.', 'error');
          /* eslint-disable-next-line no-console */
          console.error(error);
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
        setOpenConfirmationDialog(true);
      });
  };



  const CustomArrowIcon: React.FC = () => {
    return <img src={ExpandMoreIcon} alt="arrow down" style={{ width: '24px', height: '24px', margin: '0 10px 0 0' }} />;
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <>
          <div className={styles.headingBtns}>
            <Typography variant='h6' gutterBottom>
              Cycle Information
            </Typography>
            <Button variant='contained' onClick={handleDialogOpen} className="button">
              New Cycle
            </Button>
          </div>
          <Grid container spacing={3} sx={{ marginBottom: 3 }}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <InputLabel id='cycle-select-label'>Cycle</InputLabel>
                <Select
                  labelId='cycle-select-label'
                  id='cycle-select'
                  size='small'
                  value={selectedCycle?.id || ''}
                  onChange={handleCycleChange}
                  label='Cycle'
                  variant='outlined'
                  IconComponent={CustomArrowIcon}
                >
                  {cycleList.map((cycle) => (
                    <MenuItem key={cycle.id} value={cycle.id}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <span>{cycle.cycle_id}</span>
                        {cycle?.is_active && (
                          <>
                            | <Chip
                              label="Active"
                              color="success"
                              size="medium"
                              style={{ fontWeight: 'bold' }}
                              className={styles.active}
                            />
                          </>
                        )}
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label="Cycle Start Date"
                  type="date"
                  value={selectedCycle?.start_date || ''}
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label="Last Menstrual Date"
                  type="date"
                  value={selectedCycle?.last_menstrual_date || ''}
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  label="Retrieval Date"
                  type="date"
                  value={selectedCycle?.retrieval_date || ''}
                  size="small"
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  disabled
                />
              </FormControl>
            </Grid>
          </Grid>
        </>

        <Formik
          initialValues={selectedCycle || initialValues}
          onSubmit={async (values, formikHelpers) => {
            try {
              if (!selectedCycle) {
                addAlert('Please select a cycle to update.', 'error');
                return;
              }

              await handleSubmit(values, formikHelpers);
              setOpenConfirmationDialog(true);
            } catch (error) {
              /* eslint-disable-next-line no-console */
              console.error(error);
              addAlert('An error occurred while saving the cycle information.', 'error');
            }
          }}
          enableReinitialize={true}
        >
          {({ values, handleChange, errors, touched, resetForm }) => (

            <Form>
              <Box sx={{ flexGrow: 1 }}>
                {/* Stimulation Form */}
                <>
                  <Typography variant="h6" gutterBottom>
                    Stimulation
                  </Typography>
                  <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Stimulation Prototype"
                        name="stimulation_prototype"
                        size="small"
                        select
                        value={values.stimulation_prototype || ''}
                        onChange={handleChange}
                        error={touched.stimulation_prototype && Boolean(errors.stimulation_prototype)}
                        helperText={touched.stimulation_prototype && errors.stimulation_prototype}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {stimulationOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="AMH"
                        name="ahm"
                        value={values.ahm}
                        onChange={handleChange}
                        error={touched.ahm && Boolean(errors.ahm)}
                        helperText={touched.ahm && errors.ahm}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="Total Administered FSH"
                        name="total_administered_fsh"
                        value={values.total_administered_fsh}
                        onChange={handleChange}
                        error={touched.total_administered_fsh && Boolean(errors.total_administered_fsh)}
                        helperText={touched.total_administered_fsh && errors.total_administered_fsh}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="Max E2"
                        name="max_e2"
                        value={values.max_e2}
                        onChange={handleChange}
                        error={touched.max_e2 && Boolean(errors.max_e2)}
                        helperText={touched.max_e2 && errors.max_e2}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="P4 at Transfer"
                        name="p4_at_transfer"
                        value={values.p4_at_transfer}
                        onChange={handleChange}
                        error={touched.p4_at_transfer && Boolean(errors.p4_at_transfer)}
                        helperText={touched.p4_at_transfer && errors.p4_at_transfer}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="Serum FSH"
                        name="serum_fsh"
                        value={values.serum_fsh}
                        onChange={handleChange}
                        error={touched.serum_fsh && Boolean(errors.serum_fsh)}
                        helperText={touched.serum_fsh && errors.serum_fsh}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="Serum LH"
                        name="serum_lh"
                        value={values.serum_lh}
                        onChange={handleChange}
                        error={touched.serum_lh && Boolean(errors.serum_lh)}
                        helperText={touched.serum_lh && errors.serum_lh}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="Stimulation Days"
                        name="stimulation_days"
                        value={values.stimulation_days}
                        onChange={handleChange}
                        error={touched.stimulation_days && Boolean(errors.stimulation_days)}
                        helperText={touched.stimulation_days && errors.stimulation_days}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="ART Indication"
                        name="art_indication"
                        size="small"
                        select
                        value={values.art_indication || ''}
                        onChange={handleChange}
                        error={touched.art_indication && Boolean(errors.art_indication)}
                        helperText={touched.art_indication && errors.art_indication}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {artIndicationOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth error={touched.uterine_preparation && Boolean(errors.uterine_preparation)}>
                        <InputLabel id="uterine_preparation">Uterine Preparation</InputLabel>
                        <Select
                          fullWidth
                          disabled={formDisabled}
                          label="Uterine Preparation"
                          name="uterine_preparation"
                          size="small"
                          value={values.uterine_preparation?.filter((val) => val !== undefined) || []}
                          onChange={(event: SelectChangeEvent<('' | UterinePreparationEnum)[]>) => {
                            const selectedValues = event.target.value as string[];

                            if (selectedValues.length <= 5) {
                              handleChange(event);
                            } else {
                              /* eslint-disable-next-line no-console */
                              console.warn('Maximum of 5 items can be selected.');
                            }
                          }}
                          multiple
                          renderValue={(selected) =>
                            (selected as string[]).join(', ')}
                          className={styles.multiSelect}
                          IconComponent={CustomArrowIcon}
                        >

                          {uterinePreparationOptions.map((option, index) => (
                            <MenuItem key={index} value={option.value}>
                              <Checkbox
                                checked={(values.uterine_preparation || []).indexOf(option.value as UterinePreparationEnum) > -1}
                              />
                              <ListItemText primary={option.label} />
                            </MenuItem>
                          ))}
                        </Select>
                        {/* FormHelperText to display error or helperText */}
                        {touched.uterine_preparation && errors.uterine_preparation && (
                          <FormHelperText>{errors.uterine_preparation}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
                {/* Others */}
                <>
                  <Typography variant="h6" gutterBottom>
                    Others
                  </Typography>
                  <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="BMI"
                        name="bmi"
                        value={values.bmi}
                        onChange={handleChange}
                        error={touched.bmi && Boolean(errors.bmi)}
                        helperText={touched.bmi && errors.bmi}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Trigger Type"
                        name="trigger_type"
                        size="small"
                        select
                        value={values.trigger_type || ''}
                        onChange={handleChange}
                        error={touched.trigger_type && Boolean(errors.trigger_type)}
                        helperText={touched.trigger_type && errors.trigger_type}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {triggerTypeOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={12} className={styles.radioBtnsFields}>
                      <Grid item xs={12} md={3}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Post ERA</FormLabel>
                          <RadioGroup
                            name="post_era"
                            value={values.post_era ? 'yes' : 'no'}
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: event.target.name,
                                  value: event.target.value === 'yes', // Convert 'yes' to true, 'no' to false
                                },
                              });
                            }}
                            className={styles.radioBtnsGrp}
                          >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" disabled={formDisabled} />
                            <FormControlLabel value="no" control={<Radio />} label="No" disabled={formDisabled} />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Receptive</FormLabel>
                          <RadioGroup
                            name="receptive"
                            value={values.receptive ? 'yes' : 'no'}
                            onChange={(event) => {
                              handleChange({
                                target: {
                                  name: event.target.name,
                                  value: event.target.value === 'yes', // Convert 'yes' to true, 'no' to false
                                },
                              });
                            }}
                            className={styles.radioBtnsGrp}
                          >
                            <FormControlLabel value="yes" control={<Radio />} label="Yes" disabled={formDisabled} />
                            <FormControlLabel value="no" control={<Radio />} label="No" disabled={formDisabled} />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
                <div className={styles.hr}></div>
                {/* Endometrium form */}
                <>
                  <Typography variant="h6" gutterBottom>
                    Endometrium
                  </Typography>
                  <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="Thickness"
                        name="thickness"
                        value={values.thickness}
                        onChange={handleChange}
                        error={touched.thickness && Boolean(errors.thickness)}
                        helperText={touched.thickness && errors.thickness}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Pattern"
                        name="pattern"
                        size="small"
                        select
                        value={values.pattern || ''}
                        onChange={handleChange}
                        error={touched.pattern && Boolean(errors.pattern)}
                        helperText={touched.pattern && errors.pattern}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {patternOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </>
                <div className={styles.hr}></div>
                {/* IVF Form */}
                <>
                  <Typography variant="h6" gutterBottom>
                    IVF
                  </Typography>
                  <Grid container spacing={3} sx={{ marginBottom: 3 }}>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Treatment"
                        name="treatment"
                        size="small"
                        select
                        value={values.treatment || ''}
                        onChange={handleChange}
                        error={touched.treatment && Boolean(errors.treatment)}
                        helperText={touched.treatment && errors.treatment}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {treatmentOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Fertilized On"
                        name="fertilized_on"
                        size={'small'}
                        type="date"
                        value={values.fertilized_on}
                        onChange={handleChange}
                        error={touched.fertilized_on && Boolean(errors.fertilized_on)}
                        helperText={touched.fertilized_on && errors.fertilized_on}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: { max: new Date().toISOString().split('T')[0] },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        size={'small'}
                        label="Fertilized By"
                        name="fertilized_by"
                        value={values.fertilized_by}
                        onChange={handleChange}
                        error={touched.fertilized_by && Boolean(errors.fertilized_by)}
                        helperText={touched.fertilized_by && errors.fertilized_by}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Incubator"
                        name="incubator"
                        size="small"
                        select
                        value={values.incubator || ''}
                        onChange={handleChange}
                        error={touched.incubator && Boolean(errors.incubator)}
                        helperText={touched.incubator && errors.incubator}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {incubatorOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Culture Media"
                        name="culture_media"
                        size="small"
                        select
                        value={values.culture_media || ''}
                        onChange={handleChange}
                        error={touched.culture_media && Boolean(errors.culture_media)}
                        helperText={touched.culture_media && errors.culture_media}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {cultureMediaOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Oocyte Eggs Type"
                        name="oocyte_eggs_type"
                        size="small"
                        select
                        value={values.oocyte_eggs_type || ''}
                        onChange={handleChange}
                        error={touched.oocyte_eggs_type && Boolean(errors.oocyte_eggs_type)}
                        helperText={touched.oocyte_eggs_type && errors.oocyte_eggs_type}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {oocyteEggsOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="#Retrieved Eggs"
                        name="retrieved_eggs"
                        value={values.retrieved_eggs}
                        onChange={handleChange}
                        error={touched.retrieved_eggs && Boolean(errors.retrieved_eggs)}
                        helperText={touched.retrieved_eggs && errors.retrieved_eggs}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="M I"
                        name="m_1"
                        value={values.m_1}
                        onChange={handleChange}
                        error={touched.m_1 && Boolean(errors.m_1)}
                        helperText={touched.m_1 && errors.m_1}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="M II"
                        name="m_2"
                        value={values.m_2}
                        onChange={handleChange}
                        error={touched.m_2 && Boolean(errors.m_2)}
                        helperText={touched.m_2 && errors.m_2}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="GV"
                        name="gv"
                        value={values.gv}
                        onChange={handleChange}
                        error={touched.gv && Boolean(errors.gv)}
                        helperText={touched.gv && errors.gv}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="#Fertilized 1PN"
                        name="fertilized_1pn"
                        value={values.fertilized_1pn}
                        onChange={handleChange}
                        error={touched.fertilized_1pn && Boolean(errors.fertilized_1pn)}
                        helperText={touched.fertilized_1pn && errors.fertilized_1pn}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="#Fertilized 2PN"
                        name="fertilized_2pn"
                        value={values.fertilized_2pn}
                        onChange={handleChange}
                        error={touched.fertilized_2pn && Boolean(errors.fertilized_2pn)}
                        helperText={touched.fertilized_2pn && errors.fertilized_2pn}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="#Fertilized 3PN"
                        name="fertilized_3pn"
                        value={values.fertilized_3pn}
                        onChange={handleChange}
                        error={touched.fertilized_3pn && Boolean(errors.fertilized_3pn)}
                        helperText={touched.fertilized_3pn && errors.fertilized_3pn}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="#Degenerated"
                        name="degenerated"
                        value={values.degenerated}
                        onChange={handleChange}
                        error={touched.degenerated && Boolean(errors.degenerated)}
                        helperText={touched.degenerated && errors.degenerated}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="#Total Blast"
                        name="total_blast"
                        value={values.total_blast}
                        onChange={handleChange}
                        error={touched.total_blast && Boolean(errors.total_blast)}
                        helperText={touched.total_blast && errors.total_blast}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="#Biopsied Blast"
                        name="biopsied_blast"
                        value={values.biopsied_blast}
                        onChange={handleChange}
                        error={touched.biopsied_blast && Boolean(errors.biopsied_blast)}
                        helperText={touched.biopsied_blast && errors.biopsied_blast}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Sperm Donor Source"
                        name="sperm_donor_source"
                        size="small"
                        select
                        value={values.sperm_donor_source || ''}
                        onChange={handleChange}
                        error={touched.sperm_donor_source && Boolean(errors.sperm_donor_source)}
                        helperText={touched.sperm_donor_source && errors.sperm_donor_source}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {spermDonorSourceOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Egg Donor Source"
                        name="egg_donor_source"
                        size="small"
                        select
                        value={values.egg_donor_source || ''}
                        onChange={handleChange}
                        error={touched.egg_donor_source && Boolean(errors.egg_donor_source)}
                        helperText={touched.egg_donor_source && errors.egg_donor_source}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {eggDonorSourceOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Sperm Type"
                        name="sperm_type"
                        size="small"
                        select
                        value={values.sperm_type || ''}
                        onChange={handleChange}
                        error={touched.sperm_type && Boolean(errors.sperm_type)}
                        helperText={touched.sperm_type && errors.sperm_type}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {spermOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        type="number"
                        size={'small'}
                        label="Mortality Rate"
                        name="mortality_rate"
                        value={values.mortality_rate}
                        onChange={handleChange}
                        error={touched.mortality_rate && Boolean(errors.mortality_rate)}
                        helperText={touched.mortality_rate && errors.mortality_rate}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <TextField
                        fullWidth
                        disabled={formDisabled}
                        label="Morphology"
                        name="morphology"
                        size="small"
                        select
                        value={values.morphology || ''}
                        onChange={handleChange}
                        error={touched.morphology && Boolean(errors.morphology)}
                        helperText={touched.morphology && errors.morphology}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          IconComponent: CustomArrowIcon,
                        }}
                      >
                        {morphologyOptions.map((option, index) => (
                          <MenuItem key={index} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </>
                <div className={styles.hr}></div>
                <Box className={styles.buttondisplay}>
                  {!isEditing && !formDisabled && (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="button"
                      onClick={() => {
                        setOpenConfirmationDialog(true);
                        setIsSaveAction(true);  
                      }}
                    >
                      Save
                    </Button>
                  )}

                  {selectedCycle && formDisabled && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="button"
                      onClick={() => {
                        handleEdit();
                        setIsEditing(true); 
                      }}
                    >
                      Edit
                    </Button>
                  )}

                  {selectedCycle && !formDisabled && isEditing && (
                    <>
                      <Button
                        variant="outlined"
                        color="secondary"
                        className="reset"
                        onClick={() => {
                          resetForm();
                          setFormDisabled(true);
                          setIsEditing(false);
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="button"
                        onClick={() => {
                          setIsSaveAction(false); 
                        }}
                      >
                        Update
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
              {/* Confirmation Dialog */}
              <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)} classes={{ paper: styles.proceedModal }}>
                <Button onClick={() => setOpenConfirmationDialog(false)} color="primary" className="close">
                  <CloseIcon />
                </Button>
                <DialogContent>
                  <DialogContentText>
                    {!isSaveAction && (
                      <>
                        <img src={CycleIcon} alt="Successfull" style={{ width: '100px', height: '100px', margin: '0 10px 0 0' }} />
                        <h2>Cycle Details Updated Successfully!</h2>
                      </>
                    )}
                    {isSaveAction && (
                      <>
                        <img src={SuccessIcon} alt='Success' />
                        <h2>Cycle Details Saved Successfully!</h2>
                        <p>Do you want to proceed with Embryo Grading?</p>
                      </>
                    )}
                  </DialogContentText>
                </DialogContent>
                {isSaveAction && (
                  <DialogActions>
                    <Button onClick={() => setOpenConfirmationDialog(false)} color="primary" className="reset">
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenConfirmationDialog(false);
                        navigate(`/patient/${id}#aiAssessment`);
                        window.location.reload();
                      }}
                      color="primary"
                      className="button"
                    >
                      Proceed
                    </Button>
                  </DialogActions>
                )}
              </Dialog>
            </Form>
          )}
        </Formik>
        <Dialog
          open={openCreateCycleDialogOpen}
          onClose={handleDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Create a new Cycle</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} sx={{ marginBottom: 3, marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Cycle Start Date"
                  name="startDate"
                  size="small"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={createCycleState.startDate}
                  onChange={(event) =>
                    setCreateCycleState((prevState) => ({
                      ...prevState,
                      startDate: event.target.value,
                    }))
                  }
                  InputProps={{
                    inputProps: { max: new Date().toISOString().split('T')[0] },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Last Menstrual Date"
                  name="lastMenstrualDate"
                  size="small"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={createCycleState.lastMenstrualDate}
                  onChange={(event) =>
                    setCreateCycleState((prevState) => ({
                      ...prevState,
                      lastMenstrualDate: event.target.value,
                    }))
                  }
                  InputProps={{
                    inputProps: { max: new Date().toISOString().split('T')[0] },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  label="Retrieval Date"
                  name="retrievalDate"
                  size="small"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={createCycleState.retrievalDate}
                  onChange={(event) =>
                    setCreateCycleState((prevState) => ({
                      ...prevState,
                      retrievalDate: event.target.value,
                    }))
                  }
                  InputProps={{
                    inputProps: { max: new Date().toISOString().split('T')[0] },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ marginBottom: 3 }}>
              <Grid item xs={12} md={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={createCycleState.isActive}
                      onChange={(event) =>
                        setCreateCycleState((prevState) => ({
                          ...prevState,
                          isActive: event.target.checked,
                        }))
                      }
                      color="primary"
                    />
                  }
                  label="Is Active"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} className='reset'>Close</Button>
            <Button
              onClick={handleCreateCycleSubmit}
              disabled={!createCycleState.lastMenstrualDate}
              className='button'
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default CycleDetails;
