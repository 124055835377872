import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

// Create a custom Alert component using MuiAlert
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface AlertData {
  id: number; // Unique identifier for each alert
  message: string;
  severity: 'success' | 'info' | 'warning' | 'error';
}

interface AlertContextType {
  addAlert: (message: string, severity: AlertData['severity']) => void;
}

// Create the AlertContext with a default undefined value
const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = (): AlertContextType => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

// AlertProvider component to wrap the app
export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertData[]>([]); // Stack of alerts

  const addAlert = useCallback((message: string, severity: AlertData['severity']) => {
    const newAlert: AlertData = { id: Date.now(), message, severity };
    setAlerts((prevAlerts) => [...prevAlerts, newAlert]); // Add the alert to the stack
  }, []);

  const removeAlert = (id: number) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id)); // Remove alert from the stack
  };

  return (
    <AlertContext.Provider value={{ addAlert }}>
      {children}
      {alerts.map((alert, index) => (
        <Snackbar
          key={alert.id}
          open={true}
          autoHideDuration={4000}
          onClose={() => removeAlert(alert.id)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          style={{ top: `${index * 80 + 20}px` }} // Dynamically adjust the top position based on index
        >
          <Alert severity={alert.severity} onClose={() => removeAlert(alert.id)}>
            {alert.message}
          </Alert>
        </Snackbar>
      ))}
    </AlertContext.Provider>
  );
};