import React, { useEffect, useState } from 'react';
import { Doctor, Patient } from '../../types';
import styles from './styles/patientview.module.css';
import { useParams } from 'react-router-dom';
import { getDoctor, getPatient } from '../../services/apiService';
import DefaultLoader from '../../components/defaultLoader';
import { useAlert } from '../../components/alertProvider';
import { useLoader } from '../../components/loaderProvider/loader';
import { Button } from '@mui/material';
import Test1 from '../../assets/research/LOGO61.svg';
const PatientView: React.FC<{ onEditClick: () => void }> = ({ onEditClick }) => {
  const [patient, setPatient] = useState<Patient>();
  const [doctor, setDoctor] = useState<Doctor>();
  const [error, setError] = useState<string | undefined>(undefined);
  const { id } = useParams<{ id: string }>();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();

  useEffect(() => {
    if (id) {
      showLoader();
      getPatient(Number(id))
        .then((response) => {
          setPatient(response);
          getDoctor(response.doctor).then((response) => {
            setDoctor(response);

          });
        })
        .catch(() => {
          setError('Failed to fetch patient details.');
          addAlert('Failed to fetch patient details.', 'error');
        })
        .finally(() => {
          hideLoader();
        });
    }
    // eslint-disable-next-line
  }, [id]);


  if (error) {
    return <div>{error}</div>;
  }

  if (!patient) {
    return <DefaultLoader />;
  }
  return (
    <div className={styles.container}>

      {/* Patient details */}
      <div className={styles.sectionContainer}>
        <div className={styles.col1}>
          <h2 className={styles.maintitle}>Patient Details</h2>
          <div className={styles.gridContainer}>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>First Name</span>
              <span className={styles.details}>{patient.first_name}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>Last Name</span>
              <span className={styles.details}>{patient.last_name}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>Date of Birth</span>
              <span className={styles.details}>{patient.date_of_birth}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>
                Gender Assigned At Birth
              </span>
              <span className={styles.details}>{patient.gender}</span>
            </div>

            <div className={styles.detail}>
              <span className={styles.detailstitle}>MRN</span>
              <span className={styles.details}>{patient.medical_record_no}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>
                Preferred Language
              </span>
              <span className={styles.details}>{patient.preferred_language}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>Zip Code</span>
              <span className={styles.details}>{patient.pincode}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>Address</span>
              <span className={styles.details}>{patient.street}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>Country</span>
              <span className={styles.details}>{patient.country}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>City</span>
              <span className={styles.details}>{patient.city}</span>
            </div>
            <div className={styles.detail}>
              <span className={styles.detailstitle}>State</span>
              <span className={styles.details}>{patient.state}</span>
            </div>
          </div>
        </div>
        <div className={styles.divider}></div>

        {/* Partner details */}
        <div className={styles.col1}>
          <div>
            <h2 className={styles.maintitle}>Partner Details</h2>
            <div className={styles.gridContainer}>
              <div className={styles.detail}>
                <span className={styles.detailstitle}>First Name</span>
                <span className={styles.details}>{patient.partner.first_name}</span>
              </div>
              <div className={styles.detail}>
                <span className={styles.detailstitle}>Last Name</span>
                <span className={styles.details}>{patient.partner.last_name}</span>
              </div>
              <div className={styles.detail}>
                <span className={styles.detailstitle}>Date of Birth</span>
                <span className={styles.details}>{patient.partner.date_of_birth}</span>
              </div>
              <div className={styles.detail}>
                <span className={styles.detailstitle}>
                  Gender Assigned At Birth
                </span>
                <span className={styles.details}>{patient.partner.gender}</span>
              </div>
              <div className={styles.detail}>
                <span className={styles.detailstitle}>
                  Preferred Language
                </span>
                <span className={styles.details}>{patient.partner.preferred_language}</span>
              </div>
            </div>
          </div>
          <div className={styles.divider}></div>

          {/* Donor Details */}
          {patient.donor && (
            <>
              <div>
                <h2 className={styles.maintitle}>Donor Details</h2>
                <div className={styles.gridContainer}>
                  <div className={styles.detail}>
                    <span className={styles.detailstitle}>First Name</span>
                    <span className={styles.details}>{patient.donor?.first_name}</span>
                  </div>
                  <div className={styles.detail}>
                    <span className={styles.detailstitle}>Last Name</span>
                    <span className={styles.details}>{patient.donor?.last_name}</span>
                  </div>
                  <div className={styles.detail}>
                    <span className={styles.detailstitle}>Date of Birth</span>
                    <span className={styles.details}>
                      {patient.donor?.date_of_birth}
                    </span>
                  </div>
                  <div className={styles.detail}>
                    <span className={styles.detailstitle}>Donor Identity</span>
                    <span className={styles.details}>
                      {patient.donor?.donor_identity}
                    </span>
                  </div>
                  <div className={styles.detail}>
                    <span className={styles.detailstitle}>
                      Gender Assigned At Birth
                    </span>
                    <span className={styles.details}>{patient.donor?.gender}</span>
                  </div>
                  <div className={styles.detail}>
                    <span className={styles.detailstitle}>
                      Preferred Language
                    </span>
                    <span className={styles.details}>
                      {patient.donor?.preferred_language}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.divider}></div>
            </>
          )}
        </div>
      </div>
      <div className={styles.container}>
        <div className={styles.gridContainer}>
          <div className={styles.detail}>
            <span className={styles.detailstitle}>Doctor</span>
            <span className={styles.details}>{doctor?.name}</span>
          </div>
          <div className={styles.detail}>
            <span className={styles.detailstitle}>Test</span>
            <div className={styles.testLogo}><img src={Test1} alt="Aimbryo" className={styles.logo} /></div>
          </div>
          {/* Research Participant Section */}
          <div className={styles.detail}>
            <span className={styles.detailstitle}>Research Participant</span>
            <span className={styles.details}>
              {patient.is_research_participant ? 'Yes' : 'No'}
            </span>
          </div>
          {/* Portal Logged In Section */}
          <div className={styles.detail}>
            <span className={styles.detailstitle}>Portal Logged In</span>
            <span className={styles.details}>{patient.created_by}</span>
          </div>
          {/* Order Submitted By Section */}
          <div className={styles.detail}>
            <span className={styles.detailstitle}>Order Submitted By</span>
            <span className={styles.details}>{patient.order_submitted_by}</span>
          </div>
        </div>
      </div>
      <div className={styles.divider}></div>

      <div className={styles.buttonContainer}>
        <Button className="button" variant='contained' color='primary' onClick={onEditClick}>
          Edit
        </Button>
      </div>
    </div >
  );
};

export default PatientView;
