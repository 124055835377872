import React, { useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, Box } from '@mui/material';
import styles from './styles/tabView.module.css';
import PatientView from './PatientView';
import CycleDetails from '../../components/CycleDetails/CycleDetails';
import EmbryoTableView from '../../components/AI/EmbryoView';
import NewEditDemographicsForm from '../../components/Demographic/NewEditDemographicsForm';
import ProfileTop from '../../components/ProfileTop/ProfileTop';
import { useParams } from 'react-router-dom';
import { getPatient } from '../../services/apiService';
import { useAlert } from '../../components/alertProvider';
import { useLoader } from '../../components/loaderProvider/loader';
import { Patient } from '../../types';

const TabPanel: React.FC<{
  children?: React.ReactNode;
  index: number;
  value: number;
}> = ({ children, index, value }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

const TabView: React.FC<{ isEditMode: boolean }> = () => {
  const [value, setValue] = useState(0);
  const { id } = useParams<{ id: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [patient, setPatient] = useState<Patient>();
  const [error, setError] = useState<string | undefined>(undefined);

  // Define tab names in camelCase for URL hash
  const tabNames = ['aiAssessment', 'cycleDetails', 'demographic'];

  useEffect(() => {
    const hash = window.location.hash.replace('#', '');
    const tabIndex = tabNames.indexOf(hash);
    if (tabIndex !== -1) {
      setValue(tabIndex);
    }

    // Fetch patient data if id exists
    if (id) {
      showLoader();
      getPatient(Number(id))
        .then((response) => {
          setPatient(response);
        })
        .catch(() => {
          setError('Failed to fetch patient details.');
          addAlert('Failed to fetch patient details.', 'error');
        })
        .finally(() => {
          hideLoader();
        });
    }
    // eslint-disable-next-line
  }, [id]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (newValue === 0) {
      setIsEditing(false);
    }
    // Update URL hash based on selected tab
    window.location.hash = tabNames[newValue];
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div>
        <AppBar
          position="static"
          sx={{ backgroundColor: 'white', boxShadow: 'none' }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="registration tabs"
            textColor="inherit"
            TabIndicatorProps={{ style: { background: 'transparent' } }}
            classes={{ flexContainer: styles.tabGroup }}
          >
            <Tab
              label="AI Assessment"
              classes={{
                selected: styles.tabSelected,
              }}
              sx={{
                color: value === 2 ? 'var(--secondary-color)' : '#000000',
                backgroundColor: value === 0 ? '#E8E0FD' : '#F8F8F8',
                border: 'none',
              }}
            />
            <Tab
              label="Cycle Details"
              classes={{
                selected: styles.tabSelected,
              }}
              sx={{
                color: value === 0 ? 'var(--secondary-color)' : '#000000',
                backgroundColor: value === 1 ? '#E8E0FD' : '#F8F8F8',
                border: 'none',
              }}
            />
            <Tab
              label="Demographic"
              classes={{
                selected: styles.tabSelected,
              }}
              sx={{
                color: value === 1 ? 'var(--secondary-color)' : '#000000',
                backgroundColor: value === 2 ? '#E8E0FD' : '#F8F8F8',
                border: 'none',
              }}
            />
          </Tabs>
          {patient && <ProfileTop patient={patient} />}
        </AppBar>
        <TabPanel value={value} index={0}>
          <EmbryoTableView />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CycleDetails />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {isEditing ? <NewEditDemographicsForm isEditMode={isEditing} /> : <PatientView onEditClick={handleEditClick} />}
        </TabPanel>
      </div>
    </>
  );
};

export default TabView;
