import React from 'react';
import { Patient } from '../../types';
import styles from './styles/profileTop.module.css';

import iconMale from '../../assets/images/icon-male.svg';
import iconFemale from '../../assets/images/icon-female.svg';

interface ProfileTopProps {
  patient: Patient;
}

const ProfileTop: React.FC<ProfileTopProps> = ({ patient }) => {

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US');
  };

  return (
    <div className={styles.container}>
      <div className={styles.profileTop}>
        {/* Images Section */}
        <div className={styles.imageContainer}>
          <div className={styles.imageItem}>
            <span className={styles.female}>
              <img
                src={patient.gender.toLowerCase() === 'female' ? iconFemale : iconMale}
                alt={`${patient.gender} Icon`}
              />
            </span>
          </div>
          {/* Partner Icon */}
          <div className={styles.imageItem}>
            <span className={styles.male}>
              <img
                src={patient.partner.gender.toLowerCase() === 'female' ? iconFemale : iconMale}
                alt={`${patient.partner.gender} Icon`}
              />
            </span>
          </div>
          {patient.donor && (
            <div className={styles.donor}>
              <div className={styles.imageItem}>
                <span>D</span>
              </div>
            </div>
          )}
        </div>
        {/* Patient & Partner Details */}
        <div className={styles.patientInfoContainer}>
          <div className={styles.profileBasic}>
            <label>Patient | Age | Gender</label>
            <p className={styles.patientName}>
              {patient.first_name} {patient.last_name} | {patient.age}yrs | {patient.gender}
            </p>
          </div>
          <div className={styles.profileBasic}>
            <label>Partner | Age | Gender</label>
            <p className={styles.patientName}>
              {patient.partner.first_name} {patient.partner.last_name} | {patient.partner.age}yrs | {patient.partner.gender}
            </p>
          </div>
          <div className={styles.profileBasic}>
            <label>MRN</label>
            <p className={styles.patientMrn}>
              {patient.medical_record_no}
            </p>
          </div>
          <div className={styles.profileBasic}>
            <label>Last Modified</label>
            <p className={styles.date}>
              {formatDate(patient.updated_on)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileTop;
