import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

// Define the context type
interface LoaderContextType {
  showLoader: () => void;
  hideLoader: () => void;
}

// Create the LoaderContext
const LoaderContext = createContext<LoaderContextType | undefined>(undefined);

// Custom hook to use the loader
export const useLoader = (): LoaderContextType => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};
// LoaderProvider component
export const LoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  // Memoize showLoader and hideLoader to avoid re-creation on each render
  const showLoader = useCallback(() => setLoading(true), []);
  const hideLoader = useCallback(() => setLoading(false), []);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {children}

      {/* Display LinearProgress at the top when loading */}
      {loading && (
        <Box sx={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1300 }}>
          <LinearProgress />
        </Box>
      )}
    </LoaderContext.Provider>
  );
};