import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLoader } from '../loaderProvider/loader';
import { useAlert } from '../alertProvider';
import { EmbryoFormData } from '../../types';
import { createEmbryo } from '../../services/apiService';
import styles from './ai.module.css';

interface CreateEmbryoFormProps {
  id: number;
  open: boolean;
  handleClose: () => void;
}

const CreateEmbryoForm: React.FC<CreateEmbryoFormProps> = ({ id, open, handleClose }) => {
  const { showLoader, hideLoader } = useLoader();
  const { addAlert } = useAlert();
  const [error, setError] = useState<string | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [embryoFormData, setEmbryoFormData] = useState<EmbryoFormData>({
    cycle: id,
    embryo_id: '',
    fertilization_type: '',
    age: 0,
    pn_status: '',
    image_path: null,
  });
  const [embryoIds, setEmbryoIds] = useState<string[]>([]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files).filter((file) => file.type.startsWith('image/'));
      if (newFiles.length + selectedFiles.length > 10) {
        setError('You can only upload up to 10 images.');
        return;
      }
      setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setEmbryoIds((prevIds) => [
        ...prevIds,
        ...newFiles.map((file) => file.name.split('.')[0]),
      ]);
    }
  };


  const handleRemoveImage = (index: number) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setEmbryoIds((prevIds) => prevIds.filter((_, i) => i !== index));
  };

  const handleEmbryoIdChange = (index: number, value: string) => {
    setEmbryoIds((prevIds) => prevIds.map((id, i) => (i === index ? value : id)));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setEmbryoFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageClick = (file: File) => {
    const imageUrl = URL.createObjectURL(file);
    window.open(imageUrl, '_blank');
  };


  const resetForm = () => {
    setSelectedFiles([]);
    setEmbryoIds([]);
    setEmbryoFormData({
      cycle: id,
      embryo_id: '',
      fertilization_type: '',
      age: 0,
      pn_status: '',
      image_path: null,
    });
    setError(undefined);
  };

  const handleCloseWithReset = () => {
    resetForm();
    handleClose();
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (selectedFiles.length === 0) {
      setError('Please select at least one image to upload.');
      return;
    }
    if (selectedFiles.length > 10) {
      setError('You can only upload up to 10 images.');
      return;
    }
    if (!embryoFormData.fertilization_type || !embryoFormData.age || !embryoFormData.pn_status) {
      setError('Please fill all the required fields.');
      return;
    }
    if (embryoIds.some((id) => id.trim() === '')) {
      setError('Please provide an Embryo ID for each image.');
      return;
    }

    showLoader();
    setIsSubmitting(true);

    const promises = selectedFiles.map((file, index) =>
      createEmbryo({
        ...embryoFormData,
        embryo_id: embryoIds[index],
        image_path: file,
      })
    );

    Promise.all(promises)
      .then(() => {
        setError(undefined);
        addAlert('Embryos uploaded successfully!', 'success');
        resetForm();
        handleClose();
      })
      .catch(() => {
        setError('File upload failed. Please try again.');
        addAlert('File upload failed. Please try again.', 'error');
      })
      .finally(() => {
        hideLoader();
        setIsSubmitting(false);
      });
  };



  return (
    <Dialog open={open} onClose={handleCloseWithReset} fullWidth maxWidth="sm">
      <DialogTitle>Upload Embryo Information</DialogTitle>
      <DialogContent>
        <Box component="form">
          <Grid container spacing={3} sx={{ marginBottom: 3 }}>
            <Grid item xs={12}>
              <Button variant="contained" component="label" fullWidth className="labalButton">
                Select Single or Multiple Embryo Image(s)
                <input type="file" hidden accept="image/*" multiple onChange={handleFileChange} />
              </Button>
              <span className={styles.smallText}>Supported files: <span>png, jpeg.</span> Maximum upload limit: <span>10 images</span> at a time</span>
              {/* Show thumbnails of selected files */}

              {selectedFiles.map((file, index) => (
                <Grid key={index} container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={4}>
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{ width: '100px', height: '100px', objectFit: 'cover', cursor: 'pointer' }}
                      onClick={() => handleImageClick(file)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label={
                        <span>
                          Embryo ID<span style={{ color: 'red' }}> *</span>
                        </span>
                      }
                      size="small"
                      value={embryoIds[index]}
                      onChange={(e) => handleEmbryoIdChange(index, e.target.value)}
                      sx={{ mt: 1 }} 
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <IconButton
                      size="small"
                      sx={{ mt: 1 }}
                      onClick={() => handleRemoveImage(index)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Typography variant='subtitle1' gutterBottom>
            Other Information
          </Typography>
          <Grid container spacing={3} sx={{ marginBottom: 3 }}>
            {/* Other form fields */}

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <span>
                    Age of Embryo<span style={{ color: 'red' }}> *</span>
                  </span>
                }
                size="small"
                name="age"
                value={embryoFormData.age}
                onChange={handleInputChange}
                margin="normal"
                select
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <span>
                    Fertilization Type<span style={{ color: 'red' }}> *</span>
                  </span>
                }
                size="small"
                name="fertilization_type"
                value={embryoFormData.fertilization_type}
                onChange={handleInputChange}
                margin="normal"
                select
              >
                <MenuItem value="ICSI">ICSI</MenuItem>
                <MenuItem value="IVF">IVF</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={
                  <span>
                    PN Status <span style={{ color: 'red' }}> *</span>
                  </span>
                }
                size="small"
                name="pn_status"
                value={embryoFormData.pn_status}
                onChange={handleInputChange}
                margin="normal"
                select
                InputLabelProps={{
                  shrink: true,
                }}
              >
                <MenuItem value="1_PN">1 PN</MenuItem>
                <MenuItem value="2_PN">2 PN</MenuItem>
                <MenuItem value="3_PN">3 PN</MenuItem>
                <MenuItem value="Degenerated">Degenerated</MenuItem>
              </TextField>
            </Grid>

            {error && (
              <Grid item xs={12} md={6}>
                <Typography color="error" sx={{ mt: 2 }}>
                  {error}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button
          onClick={handleCloseWithReset}
          color="secondary"
          variant="contained"
          className="reset"
          sx={{ fontSize: '16px', ml: 2, height: '48px' }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          variant="contained"
          className="button"
          sx={{ fontSize: '16px', ml: 2, height: '48px' }}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEmbryoForm;