import React, { useState } from 'react';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Pagination, PaginationItem, TooltipProps, Tooltip,
  ThemeProvider, createTheme, styled, tooltipClasses,
} from '@mui/material';
import { Embryo } from '../../types';
import styles from './embryoTable.module.css';
import ViewIcon from '../../assets/images/eye.png';
import DeleteIcon from '../../assets/images/trash.png';
import AIsIcon from '../../assets/images/AIscoreIcon.png';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className || '' }} arrow />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',  
    color: '#000',          
    fontSize: '0.8rem',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    borderRadius: '10px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#fff',           
  },
}));

interface EmbryoTableProps {
  embryos: Embryo[];
  onDelete: (embryoId: number) => void;
}

const EmbryoTable: React.FC<EmbryoTableProps> = ({ embryos, onDelete }) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedEmbryoId, setSelectedEmbryoId] = useState<number | null>(null);
  const [openImageDialog, setOpenImageDialog] = useState(false);  
  const [imageUrl, setImageUrl] = useState<string | null>(null);  
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const totalPages = Math.ceil(embryos.length / rowsPerPage);

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins, sans-serif',
    },
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            borderRadius: '20px',
            overflow: 'hidden',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            borderRadius: '12px',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 600,
          },
        },
      },
    },
  });

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage - 1);
  };

  const handleDeleteClick = (embryoId: number) => {
    setSelectedEmbryoId(embryoId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEmbryoId(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedEmbryoId) {
      onDelete(selectedEmbryoId);
      handleCloseDialog();
    }
  };

  const handleViewImage = (embryo: Embryo) => {
    if (!embryo.ai_score || embryo.ai_score === 'NULL') {
      setErrorMessage(
        'Invalid Image Uploaded.\nThe uploaded image could not be processed.\nPlease ensure that it is a valid embryo image suitable for AI grading.'
      );
      setErrorDialogOpen(true);
      return;
    }

    setImageUrl(embryo.image_url);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setImageUrl(null);
  };
  const handleCloseErrorDialog = () => {
    setErrorDialogOpen(false);
    setErrorMessage('');
  };


  return (
    <ThemeProvider theme={theme}>
      < >
        <TableContainer>
          <Table className={styles.custom_table}>
            <TableHead>
              <TableRow className={styles.custom_table_head}>
                <TableCell>Case ID</TableCell>
                <TableCell>Embryo ID</TableCell>
                <TableCell>IVF/ICSI</TableCell>
                <TableCell>Age of Embryo</TableCell>
                <TableCell>PN Status</TableCell>
                <TableCell>Emb Image</TableCell>
                <TableCell>AI Grade</TableCell>
                <TableCell>AI Score</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {embryos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((embryo) => (
                <TableRow key={embryo.embryo_id} className={styles.custom_table_body}>
                  <TableCell>{embryo.case_id}</TableCell>
                  <TableCell>{embryo.embryo_id}</TableCell>
                  <TableCell>{embryo.fertilization_type}
                  </TableCell>
                  <TableCell>Day {embryo.age}</TableCell>
                  <TableCell>{embryo.pn_status.replace(/_/g, ' ')}</TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      onClick={() => handleViewImage(embryo)}
                      className={styles.viewBtn}
                    >
                      <img
                        src={ViewIcon}
                        alt="View Icon"
                        style={{ width: '18px', height: '18px', marginRight: '8px' }}
                      />
                      View
                    </Button>
                  </TableCell>
                  <TableCell>
                    {embryo.ai_grade === 'NA' ? (
                      <StyledTooltip title="Please ensure the uploaded embryo image meets the AI grading requirements and try again.">
                        <span className={styles.aiGradeBG}>{embryo.ai_grade}</span>
                      </StyledTooltip>
                    ) : (
                      <span className={styles.aiGradeBG}>{embryo.ai_grade}</span>
                    )}
                  </TableCell>

                  <TableCell>
                    <p className={styles.aiScoreBG}>
                      <span className={styles.aiScoreText}
                      >
                        <img
                          src={AIsIcon}
                          alt="AIscore Icon"
                          style={{ width: '18px', height: '18px', marginRight: '8px' }}

                        />
                        {embryo.ai_score === 'NA' ? 'AI Score : NA' : `AI Score : ${embryo.ai_score}%`}
                      </span>
                    </p>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="text"
                      onClick={() => handleDeleteClick(embryo.id)}
                      aria-label="delete"

                    >
                      <img
                        src={DeleteIcon}
                        alt="Delete Icon"
                        style={{ width: '18px', height: '18px', marginRight: '8px' }}
                      />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <p>Showing {page * rowsPerPage + 1} to {Math.min((page + 1) * rowsPerPage, embryos.length)} of {embryos.length} entries</p>
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handleChangePage}
            shape="rounded"
            renderItem={(item) => (
              <PaginationItem
                {...item}
                sx={{
                  fontSize: '14px',
                  borderRadius: '12px',
                  '&.Mui-selected': {
                    backgroundColor: 'var(--secondary-color) !important',
                    color: 'white',
                  },
                }}
              />
            )}
          />
        </div>

        {/* Confirmation Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>Confirm Deletion</DialogTitle>
          <DialogContent>Are you sure you want to delete this embryo record?</DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>No</Button>
            <Button onClick={handleConfirmDelete} color="error">Yes</Button>
          </DialogActions>
        </Dialog>

        {/* Image Modal */}
        <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
          <DialogTitle>Embryo Image</DialogTitle>
          <DialogContent>
            {imageUrl && (
              <img src={imageUrl} alt="Embryo" style={{ width: '100%', height: 'auto' }} />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageDialog}>Close</Button>
          </DialogActions>
        </Dialog>
        {/* Error Dialog */}
        <Dialog open={errorDialogOpen} onClose={handleCloseErrorDialog}>
          <DialogTitle className='error' style={{ textAlign: 'center' }}>Error</DialogTitle>
          <DialogContent>
            <p style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{errorMessage}</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseErrorDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      </>
    </ThemeProvider>
  );
};

export default EmbryoTable;