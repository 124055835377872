import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@mui/material';
import { CountryData, Doctor, Patient, } from '../../types';
import {
  createPatient,
  getCitiesList,
  getCountryList,
  getDoctorList,
  getGenderList,
  getStateList,
  getPatient,
  updatePatient,
} from '../../services/apiService';
import styles from './demographic.module.css';
import { useAlert } from '../alertProvider';
import { Form, Formik, FormikErrors, FormikHelpers, getIn, setIn } from 'formik';
import * as Yup from 'yup';
import { useLoader } from '../loaderProvider/loader';
import LOGO21 from '../../assets/research/LOGO21.svg';
import LOGO31 from '../../assets/research/LOGO31.svg';
import LOGO41 from '../../assets/research/LOGO41.svg';
import LOGO51 from '../../assets/research/LOGO51.svg';
import LOGO61 from '../../assets/research/LOGO61.svg';
import SuccessIcon from '../../assets/images/green-tick.png';
import iconMale from '../../assets/images/icon-male.svg';
import iconFemale from '../../assets/images/icon-female.svg';
import neutralIcon from '../../assets/images/user_icon.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';



const NewEditDemographicsForm: React.FC<{ isEditMode: boolean }> = ({ isEditMode = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { addAlert } = useAlert();
  const { showLoader, hideLoader } = useLoader();
  const [genderOptions, setGenderOptions] = useState<string[]>([]);
  const [countriesOptions, setCountriesOptions] = useState<string[]>([]);
  const [stateOptions, setStateOptions] = useState<string[]>([]);
  const [cityOptions, setCityOptions] = useState<string[]>([]);
  const [doctorOptions, setDoctorOptions] = useState<Doctor[]>([]);
  const [donorType, setDonorType] = useState<string>('None');

  const [openDialog, setOpenDialog] = useState(false);
  const [newPatientId, setNewPatientId] = useState<number | null>(null);
  const [patientName, setPatientName] = useState('');


  // Define your checkbox options
  const checkboxOptions = [
    { name: 'AIMBRYO', value: 'AIMBRYO', image: LOGO61 },
    { name: 'AISA', value: 'AISA', image: LOGO41 },
    { name: 'ERAI', value: 'ERAI', image: LOGO51 },
    { name: 'AIOO', value: 'AIOO', image: LOGO31 },
    { name: 'ALIKE', value: 'ALIKE', image: LOGO21 },
  ];

  const [initialValues, setInitialValues] = useState<Patient>({
    first_name: '',
    last_name: '',
    date_of_birth: '',
    updated_on: '',
    age: '',
    gender: '',
    preferred_language: undefined,
    clinical_indication: undefined,
    email: '',
    phone: '',
    is_research_participant: false,
    order_submitted_by: '',
    pincode: '',
    street: '',
    city: '',
    state: '',
    country: '',
    ai_assessment_type: undefined,
    donor: {
      first_name: '',
      last_name: '',
      date_of_birth: '',
      gender: '',
      preferred_language: undefined,
      donor_identity: '',
      type: undefined,
    },
    doctor: 0,
    partner: {
      first_name: '',
      age: '',
      last_name: '',
      date_of_birth: '',
      gender: '',
      email: '',
      phone: '',
      preferred_language: undefined,
    },
    agree: false,
  });

  const validationSchema = Yup.object({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    date_of_birth: Yup.string().required('Date of Birth is required'), // Consider adding a date format validation
    gender: Yup.string().required('Gender is required'),
    preferred_language: Yup.mixed().nullable(),
    clinical_indication: Yup.mixed().nullable(),
    email: Yup.string().nullable(),
    phone: Yup.string()
      .matches(/^[0-9]*$/, 'Phone number can only contain numbers')
      .length(10, 'Phone number must be exactly 10 digits')
      .required('Phone number is required')
      .nullable(),
    ai_assessment_type: Yup.mixed().nullable(),
    is_research_participant: Yup.boolean().required('Research Participant status is required'),
    order_submitted_by: Yup.string().nullable(),
    pincode: Yup.string().nullable(),
    street: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(),
    country: Yup.string().nullable(),
    // Donor validation
    donor: Yup.object().nullable(),

    // Partner validation
    partner: Yup.object().shape({
      first_name: Yup.string().required('Partner First Name is required'),
      last_name: Yup.string().required('Partner Last Name is required'),
      date_of_birth: Yup.string().required('Partner Date of Birth is required'),
      gender: Yup.string().required('Partner Gender is required'),
      email: Yup.string().nullable(),
      phone: Yup.string()
        .matches(/^[0-9]*$/, 'Phone number can only contain numbers')
        .length(10, 'Phone number must be exactly 10 digits')
        .required('Phone number is required')
        .nullable(),
      preferred_language: Yup.mixed().nullable(),
    }),

    doctor: Yup.number().required('Doctor is required').min(1, 'Doctor is required'),
    agree: Yup.boolean().oneOf([true], 'You must agree before submitting'),
  });
  const handleSubmit = async (values: Patient, { setSubmitting, setErrors }: FormikHelpers<Patient>) => {
    showLoader();
    /* eslint-disable-next-line no-console */
    console.log(values);
    /* eslint-disable-next-line no-console */
    console.log('Submitting values:', values);   
    // Handle donor type
    if (donorType === 'None') {
      values.donor = undefined;
    } else {
      values.donor = {
        ...values.donor,
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        type: donorType,
      };
    }

    const apiCall = isEditMode && id ? updatePatient(Number(id), values) : createPatient(values);

    apiCall
      .then((response: Patient) => {
        if (isEditMode) {
          addAlert('Patient information updated successfully', 'success');
        } else {
          const newPatientId = response.id!;
          setNewPatientId(newPatientId);
          setPatientName(`${response.first_name} ${response.last_name}`);
          setOpenDialog(true);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.type === 'validation_error') {
          const apiErrors = error.response.data.errors;
          let formikErrors: FormikErrors<Patient> = {};

          apiErrors.forEach((error: { attr: keyof Patient; detail: string }) => {
            formikErrors = setIn(formikErrors, error.attr, error.detail);
          });

          setErrors(formikErrors);
        } else {
          addAlert('An error occurred while creating/updating the patient.', 'error');
          /* eslint-disable-next-line no-console */
          console.error('An error occurred', error);
        }
      })
      .finally(() => {
        hideLoader();
        setSubmitting(false);
        if (isEditMode) {
          navigate('/patient/');
        }
      });
  };


  const fetchData = useCallback(async () => {
    showLoader();
    try {
      if (isEditMode && id) {
        const patientData = await getPatient(Number(id));
        setInitialValues(patientData);
        setDonorType(patientData.donor?.type || 'None');

        // Fetch and set the states list based on the patient's country
        if (patientData.country) {
          const stateData = await getStateList(patientData.country);
          setStateOptions(stateData.data.states.map((state) => state.name));
        }

        // Fetch and set the cities list based on the patient's state and country
        if (patientData.country && patientData.state) {
          const cityData = await getCitiesList(patientData.state, patientData.country);
          setCityOptions(cityData.data);
        }
      }

      const userData = JSON.parse(localStorage.getItem('user') || '{}');
      const clinicId = userData?.clinic?.id;
      if (!clinicId) {
        /*eslint-disable no-console */
        console.error('Clinic ID is missing in user profile.');
        return;
      }

      const [genderData, doctorData, countryData] = await Promise.all([
        getGenderList(),
        getDoctorList({ clinic_id: clinicId }),
        getCountryList(),
      ]);

      setGenderOptions(genderData.objects.map((obj) => obj.name));
      setDoctorOptions(doctorData.objects);
      setCountriesOptions(countryData.data.map((country: CountryData) => country.country));
    } catch (error) {
      /*eslint-disable no-console */
      console.error('Error fetching data:', error);
    } finally {
      hideLoader();
    }
  }, [isEditMode, id, hideLoader, showLoader]);


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [fetchData]);

  return (
    <div className={styles.mainContainer}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        context={{ donorType }}
      >
        {({ values, handleChange, errors, touched }) => (
          <Form>
            <Box sx={{ flexGrow: 1 }}>
              {/* Patient Section */}
              <div className={styles.sectionContainer}>
                <div className={styles.col1}>
                  <div className={styles.imageContainer}>
                    <span className={
                      values.gender
                        ? values.gender.toLowerCase() === 'male'
                          ? styles.male
                          : styles.female
                        : styles.neutral
                    }>
                      <img
                        src={
                          values.gender
                            ? values.gender.toLowerCase() === 'male'
                              ? iconMale
                              : iconFemale
                            : neutralIcon
                        }
                        alt={
                          values.gender
                            ? values.gender.toLowerCase() === 'male'
                              ? 'Male Icon'
                              : 'Female Icon'
                            : 'Neutral Icon'
                        }
                      />
                    </span>

                  </div>
                  <Typography variant="h6" gutterBottom>
                    Patient Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        size={'small'}
                        label={
                          <span>
                            First Name<span style={{ color: 'red' }}> *</span>
                          </span>
                        }
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        error={touched.first_name && Boolean(errors.first_name)}
                        helperText={touched.first_name && errors.first_name}

                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={
                          <span>
                            Last Name<span style={{ color: 'red' }}> *</span>
                          </span>
                        }
                        size={'small'}
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        error={touched.last_name && Boolean(errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={
                          <span>
                            Date of Birth<span style={{ color: 'red' }}> *</span>
                          </span>
                        }
                        name="date_of_birth"
                        size={'small'}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        value={values.date_of_birth}
                        onChange={handleChange}
                        error={touched.date_of_birth && Boolean(errors.date_of_birth)}
                        helperText={touched.date_of_birth && errors.date_of_birth}
                        InputProps={{
                          inputProps: { max: new Date().toISOString().split('T')[0] },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={
                          <span>
                            Gender Assigned at Birth<span style={{ color: 'red' }}> *</span>
                          </span>
                        }
                        size={'small'}
                        name="gender"
                        select
                        value={values.gender}
                        onChange={handleChange}
                        error={touched.gender && Boolean(errors.gender)}
                        helperText={touched.gender && errors.gender}
                      >
                        {genderOptions.map((gender, index) => (
                          <MenuItem key={index} value={gender}>
                            {gender}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Preferred Language"
                        name="preferred_language"
                        size={'small'}
                        select
                        value={values.preferred_language || ''}
                        onChange={handleChange}
                      >
                        <MenuItem value='ARABIC'>Arabic</MenuItem>
                        <MenuItem value='CHINESE'>Chinese</MenuItem>
                        <MenuItem value='ENGLISH'>English</MenuItem>
                        <MenuItem value='FARSI'>Farsi</MenuItem>
                        <MenuItem value='FRENCH'>French</MenuItem>
                        <MenuItem value='GERMAN'>German</MenuItem>
                        <MenuItem value='INDIAN'>Indian</MenuItem>
                        <MenuItem value='JAPANESE'>Japanese</MenuItem>
                        <MenuItem value='KOREAN'>Korean</MenuItem>
                        <MenuItem value='MANDARIN'>Mandarin</MenuItem>
                        <MenuItem value='PORTUGUESE'>Portuguese</MenuItem>
                        <MenuItem value='SPANISH'>Sppanish</MenuItem>
                        <MenuItem value='OTHER'>Other</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Clinical Indication"
                        name="clinical_indication"
                        size={'small'}
                        select
                        value={values.clinical_indication || ''}
                        onChange={handleChange}
                      >
                        <MenuItem value="">--</MenuItem>
                        <MenuItem value="IVF">IVF</MenuItem>
                        <MenuItem value="IUI">IUI</MenuItem>
                        <MenuItem value="FET">FET</MenuItem>
                        <MenuItem value="DIAGNOSTIC">DIAGNOSTIC</MenuItem>
                        <MenuItem value="OTHER">OTHER</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        size={'small'}
                        value={values.email || ''}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Phone No"
                        name="phone"
                        size={'small'}
                        value={values.phone || ''}
                        onChange={handleChange}
                        error={touched.phone && Boolean(errors.phone)}
                        helperText={touched.phone && errors.phone}
                        type="tel"
                        onKeyDown={(e) => {
                          if (
                            (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') ||
                            (values.phone && values.phone.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete')
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Zip Code"
                        name="pincode"
                        size={'small'}
                        value={values.pincode || ''}
                        onChange={handleChange}
                        error={touched.pincode && Boolean(errors.pincode)}
                        helperText={touched.pincode && errors.pincode}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Address"
                        name="street"
                        size={'small'}
                        value={values.street || ''}
                        onChange={handleChange}
                        error={touched.street && Boolean(errors.street)}
                        helperText={touched.street && errors.street}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Country"
                        name="country"
                        size="small"
                        select
                        value={values.country}
                        onChange={async (event) => {
                          const { value } = event.target;
                          handleChange(event);
                          const stateData = await getStateList(value);
                          setStateOptions(stateData.data.states.map((state) => state.name));
                        }}
                        error={touched.country && Boolean(errors.country)}
                        helperText={touched.country && errors.country}
                      >
                        {countriesOptions.map((country, index) => (
                          <MenuItem key={index} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="State"
                        name="state"
                        size="small"
                        select
                        value={values.state || ''}
                        onChange={async (event) => {
                          const { value } = event.target;
                          handleChange(event);

                          if (!values.country) {
                            return;
                          }
                          const cityData = await getCitiesList(value, values.country);
                          setCityOptions(cityData.data);
                        }}
                        error={touched.state && Boolean(errors.state)}
                        helperText={touched.state && errors.state}
                      >
                        {stateOptions.map((state, index) => (
                          <MenuItem key={index} value={state}>
                            {state}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="City"
                        name="city"
                        size="small"
                        select
                        value={values.city || ''}
                        onChange={handleChange}
                        error={touched.city && Boolean(errors.city)}
                        helperText={touched.city && errors.city}
                      >
                        {cityOptions.map((city, index) => (
                          <MenuItem key={index} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>


                  </Grid>
                </div>
                {/* Partner Section */}
                <div className={`${styles.col1} ${styles['no-border']}`}>
                  <>
                    <div className={styles.imageContainer}>
                      <span className={
                        values.partner.gender
                          ? values.partner.gender.toLowerCase() === 'female'
                            ? styles.female
                            : styles.male
                          : styles.neutral
                      }>
                        <img
                          src={
                            values.partner.gender
                              ? values.partner.gender.toLowerCase() === 'female'
                                ? iconFemale
                                : iconMale
                              : neutralIcon
                          }
                          alt={
                            values.partner.gender
                              ? values.partner.gender.toLowerCase() === 'female'
                                ? 'Female Icon'
                                : 'Male Icon'
                              : 'Neutral Icon'
                          }
                        />
                      </span>
                    </div>
                    <Typography variant="h6" gutterBottom  >
                      Partner Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          size="small"
                          label={
                            <span>
                              First Name<span style={{ color: 'red' }}> *</span>
                            </span>
                          }
                          name="partner.first_name"
                          value={values.partner.first_name}
                          onChange={handleChange}
                          error={touched.partner?.first_name && Boolean(errors.partner?.first_name)}
                          helperText={touched.partner?.first_name && errors.partner?.first_name}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label={
                            <span>
                              Last Name<span style={{ color: 'red' }}> *</span>
                            </span>
                          }
                          size="small"
                          name="partner.last_name"
                          value={values.partner.last_name}
                          onChange={handleChange}
                          error={touched.partner?.last_name && Boolean(errors.partner?.last_name)}
                          helperText={touched.partner?.last_name && errors.partner?.last_name}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label={
                            <span>
                              Date of Birth<span style={{ color: 'red' }}> *</span>
                            </span>
                          }
                          name="partner.date_of_birth"
                          size="small"
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          value={values.partner.date_of_birth}
                          onChange={handleChange}
                          error={touched.partner?.date_of_birth && Boolean(errors.partner?.date_of_birth)}
                          helperText={touched.partner?.date_of_birth && errors.partner?.date_of_birth}
                          InputProps={{
                            inputProps: { max: new Date().toISOString().split('T')[0] },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label={
                            <span>
                              Gender Assigned at Birth<span style={{ color: 'red' }}> *</span>
                            </span>
                          }
                          size="small"
                          name="partner.gender"
                          select
                          value={values.partner.gender}
                          onChange={handleChange}
                          error={touched.partner?.gender && Boolean(errors.partner?.gender)}
                          helperText={touched.partner?.gender && errors.partner?.gender}
                        >
                          {genderOptions.map((gender, index) => (
                            <MenuItem key={index} value={gender}>
                              {gender}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Email"
                          name="partner.email"
                          size={'small'}
                          value={values.partner?.email || ''}
                          onChange={handleChange}
                          error={touched.partner?.email && Boolean(errors.partner?.email)}
                          helperText={touched.partner?.email && errors.partner?.email}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Phone No"
                          name="partner.phone"
                          size={'small'}
                          value={values.partner?.phone || ''}
                          onChange={handleChange}
                          error={touched.partner?.phone && Boolean(errors.partner?.phone)}
                          helperText={touched.partner?.phone && errors.partner?.phone}
                          type="tel"
                          onKeyDown={(e) => {
                            if (
                              (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete') ||
                              (values.partner.phone && values.partner.phone.length >= 10 && e.key !== 'Backspace' && e.key !== 'Delete')
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Preferred Language"
                          name="partner.preferred_language"
                          size={'small'}
                          select
                          value={values.partner.preferred_language || ''}
                          onChange={handleChange}
                        >
                          <MenuItem value='ARABIC'>Arabic</MenuItem>
                          <MenuItem value='CHINESE'>Chinese</MenuItem>
                          <MenuItem value='ENGLISH'>English</MenuItem>
                          <MenuItem value='FARSI'>Farsi</MenuItem>
                          <MenuItem value='FRENCH'>French</MenuItem>
                          <MenuItem value='GERMAN'>German</MenuItem>
                          <MenuItem value='INDIAN'>Indian</MenuItem>
                          <MenuItem value='JAPANESE'>Japanese</MenuItem>
                          <MenuItem value='KOREAN'>Korean</MenuItem>
                          <MenuItem value='MANDARIN'>Mandarin</MenuItem>
                          <MenuItem value='PORTUGUESE'>Portuguese</MenuItem>
                          <MenuItem value='SPANISH'>Sppanish</MenuItem>
                          <MenuItem value='OTHER'>Other</MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>
                  </>
                  <div className={styles.hr}>&nbsp;</div>

                  {/* Donor Section */}
                  <>
                    <div className={styles.imageContainer}>
                      <span className={styles.donorImg}>D</span>
                    </div>
                    <Typography variant="h6" gutterBottom sx={{ marginTop: 3 }}>
                      Donor Information
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend"></FormLabel>
                          <RadioGroup
                            row
                            name="donorType"
                            value={donorType}
                            onChange={(event) => setDonorType(event.target.value)}
                          >
                            <FormControlLabel value="None" control={<Radio />} label="None" />
                            <FormControlLabel value="OOCYTE" control={<Radio />} label="OOCYTE" />
                            <FormControlLabel value="SPERM" control={<Radio />} label="SPERM" />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      {/* Donor Fields (only visible if donorType is not 'None') */}
                      {donorType !== 'None' && (
                        <>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              size="small"
                              label={
                                <span>
                                  First Name<span style={{ color: 'red' }}> *</span>
                                </span>
                              }
                              name="donor.first_name"
                              value={values.donor?.first_name || ''}
                              onChange={handleChange}
                              error={Boolean(getIn(touched, 'donor.first_name') && getIn(errors, 'donor.first_name'))}
                              helperText={getIn(touched, 'donor.first_name') && getIn(errors, 'donor.first_name')}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              size="small"
                              label={
                                <span>
                                  Last Name<span style={{ color: 'red' }}> *</span>
                                </span>
                              }
                              name="donor.last_name"
                              value={values.donor?.last_name || ''}
                              onChange={handleChange}
                              error={Boolean(getIn(touched, 'donor.last_name') && getIn(errors, 'donor.last_name'))}
                              helperText={getIn(touched, 'donor.last_name') && getIn(errors, 'donor.last_name')}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              size="small"
                              label={
                                <span>
                                  Date of Birth<span style={{ color: 'red' }}> *</span>
                                </span>
                              }
                              name="donor.date_of_birth"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              value={values.donor?.date_of_birth || ''}
                              onChange={handleChange}
                              error={Boolean(getIn(touched, 'donor.date_of_birth') && getIn(errors, 'donor.date_of_birth'))}
                              helperText={getIn(touched, 'donor.date_of_birth') && getIn(errors, 'donor.date_of_birth')}
                              InputProps={{
                                inputProps: { max: new Date().toISOString().split('T')[0] },
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label={
                                <span>
                                  Gender Assigned at Birth<span style={{ color: 'red' }}> *</span>
                                </span>
                              }
                              size="small"
                              name="donor.gender"
                              select
                              value={values.donor?.gender || ''}
                              onChange={handleChange}
                              error={Boolean(getIn(touched, 'donor.gender') && getIn(errors, 'donor.gender'))}
                              helperText={getIn(touched, 'donor.gender') && getIn(errors, 'donor.gender')}
                            >
                              {genderOptions.map((gender, index) => (
                                <MenuItem key={index} value={gender}>
                                  {gender}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label="Preferred Language"
                              name="donor.preferred_language"
                              size={'small'}
                              select
                              value={values.donor?.preferred_language || ''}
                              onChange={handleChange}
                            >
                              <MenuItem value='ARABIC'>Arabic</MenuItem>
                              <MenuItem value='CHINESE'>Chinese</MenuItem>
                              <MenuItem value='ENGLISH'>English</MenuItem>
                              <MenuItem value='FARSI'>Farsi</MenuItem>
                              <MenuItem value='FRENCH'>French</MenuItem>
                              <MenuItem value='GERMAN'>German</MenuItem>
                              <MenuItem value='INDIAN'>Indian</MenuItem>
                              <MenuItem value='JAPANESE'>Japanese</MenuItem>
                              <MenuItem value='KOREAN'>Korean</MenuItem>
                              <MenuItem value='MANDARIN'>Mandarin</MenuItem>
                              <MenuItem value='PORTUGUESE'>Portuguese</MenuItem>
                              <MenuItem value='SPANISH'>Sppanish</MenuItem>
                              <MenuItem value='OTHER'>Other</MenuItem>
                            </TextField>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              size="small"
                              label={
                                <span>
                                  Donor Identity<span style={{ color: 'red' }}> *</span>
                                </span>
                              }
                              name="donor.donor_identity"
                              value={values.donor?.donor_identity || ''}
                              onChange={handleChange}
                              error={Boolean(getIn(touched, 'donor.donor_identity') && getIn(errors, 'donor.donor_identity'))}
                              helperText={getIn(touched, 'donor.donor_identity') && getIn(errors, 'donor.donor_identity')}
                            >
                              {genderOptions.map((gender, index) => (
                                <MenuItem key={index} value={gender}>
                                  {gender}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                </div>
              </div>
              <div className={styles.hr}>&nbsp;</div>
              <>
                <Grid container spacing={2} sx={{ marginTop: 3 }}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={
                        <span>
                          Doctor<span style={{ color: 'red' }}> *</span>
                        </span>
                      }
                      name="doctor"
                      size="small"
                      select
                      value={values.doctor}
                      onChange={handleChange}
                      error={touched.doctor && Boolean(errors.doctor)}
                      helperText={touched.doctor && errors.doctor}
                    >
                      {doctorOptions.map((doctor: Doctor, index) => (
                        <MenuItem key={index} value={doctor.id}>
                          {doctor.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div className={styles.checkboxGroup}>
                      {checkboxOptions.map((option) => (
                        <div key={option.value} className={styles.checkboxItem}>
                          <label className={styles.customCheckbox}>
                            <input
                              type="checkbox"
                              value={option.value}
                              checked={option.value === 'AIMBRYO'}
                              disabled={option.value !== 'AIMBRYO'}
                            />
                            <div className={styles.checkboxIndicator}>
                              <img src={option.image} alt={option.name} className={styles.logo} />
                              <span className={styles.checkboxLabel}>{option.name}</span>
                            </div>
                          </label>
                        </div>
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="is_research_participant"
                          checked={values.is_research_participant}
                          onChange={handleChange}
                        />
                      }
                      label="Research Participant"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Order Submitted By"
                      name="order_submitted_by"
                      size="small"
                      value={values.order_submitted_by || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </>
              <div className={styles.hr}>&nbsp;</div>
              <Grid container spacing={2} sx={{ marginTop: 3 }}>
                <FormControl error={touched.agree && Boolean(errors.agree)} component="fieldset" >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="agree"
                        checked={values.agree}
                        onChange={handleChange}
                      />
                    }

                    className={styles.agreeCheckbox}
                    label={
                      <span className={styles.agreeLabel}>
                        By submitting this electronic test requisition form, I certify that (i) I agree to the terms and conditions written on the VIDAI PLUS Informed Consent and Privacy Disclosure; (ii) I have provided the VIDAI PLUS Informed Consent and Privacy Disclosure to the patient/partner, and they understand and agree to have this testing performed by VIDAI PLUS lab; (iii) the informed consent obtained from the patient meets the requirements of applicable law; (iv) and I am the authorized physician or an individual authorized by the physician to submit this test order.
                      </span>
                    }
                  />
                  {touched.agree && errors.agree && (
                    <FormHelperText>{errors.agree}</FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* Submit Button */}
              <Box className={styles.buttondisplay} sx={{ marginTop: 3 }}>
                <Button variant='outlined' color='secondary' className="reset" onClick={() => {
                  navigate('/patient');
                }}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" type="submit" className="button">
                  {isEditMode ? 'Update Patient' : 'Save and Next'}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>

      {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} classes={{ paper: styles.proceedModal }} >
        <DialogContent>
          <DialogContentText>
            <div className={styles.successImg}><img src={SuccessIcon} alt='Success' /></div>
            <h2>Registration Done!</h2>
            <p><span>{patientName}</span> is successfully registered</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { navigate(-1); }} color="primary" className="reset" >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
              navigate(`/patient/${newPatientId}#cycleDetails`);
            }}
            color="primary"
            className="button"
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewEditDemographicsForm;