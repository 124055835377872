/* eslint-disable @typescript-eslint/no-explicit-any */
import { deleteRequest, downloadReport, getRequest, postRequest, putRequest } from './httpService';
import {
  CustomLogin,
  Doctor,
  Gender,
  ListPatient,
  PaginatedResponse,
  LoginResponse,
  Patient,
  UserProfile,
  ApiResponseState,
  ApiResponseCountry, ApiResponseCity, Cycle, CreateCycle, EmbryoFormData, Embryo, TicketFormData, Ticket,
} from '../types';
import {
  getPatientListUrl,
  getPatientUrl,
  getGenderUrl,
  getMyProfileUrl,
  loginUserUrl,
  getDoctorUrl,
  getCountryUrl,
  getStateUrl,
  createPatientUrl,
  getCitiesListUrl,
  getCycleListUrl,
  getCycleUrl,
  createCycleUrl,
  getCreateEmbryoUrl,
  getEmbryoListUrl,
  deleteEmbryoUrl,
  getDoctorListUrl,
  cycleReportUrl,
  createTicketUrl,
  getTicketListUrl,
  getTicketUrl,
  uploadConsentFormUrl,
  deleteConsentFormUrl,
} from './urls';
import { AxiosResponse } from 'axios';


export function loginUser(loginData: CustomLogin): Promise<LoginResponse> {
  return postRequest<LoginResponse>(loginUserUrl(), loginData);
}

export async function getPatientList(startDate: string, endDate: string, search: string): Promise<PaginatedResponse<ListPatient[]>> {
  const url = getPatientListUrl();
  const query = new URLSearchParams({
    created_on_after: startDate,
    created_on_before: endDate,
    search: search
  });

  return await getRequest(`${url}?${query}`, { responseType: 'json' });
}

export async function getPatient(id: number): Promise<Patient> {
  return await getRequest(getPatientUrl(id), { responseType: 'json' });
}

export async function updatePatient(id: number, data: Patient): Promise<Patient> {
  return await putRequest(getPatientUrl(id), data, { responseType: 'json' });
}

export async function createPatient(data: Patient): Promise<Patient> {
  return await postRequest(createPatientUrl(), data, { responseType: 'json' });
}

export async function getMyProfile(): Promise<UserProfile> {
  return await getRequest(getMyProfileUrl(), { responseType: 'json' });
}

export async function getGenderList(): Promise<PaginatedResponse<Gender[]>> {
  return await getRequest(getGenderUrl(), { responseType: 'json' });
}

export async function getDoctorList(params?: { clinic_id: number }): Promise<PaginatedResponse<Doctor[]>> { 
  const url = params?.clinic_id
    ? `${getDoctorListUrl()}?clinic_id=${params.clinic_id}`
    : getDoctorListUrl();

  return await getRequest(url, { responseType: 'json' });
}


export async function getDoctor(id: number): Promise<Doctor> {
  return await getRequest(getDoctorUrl(id), { responseType: 'json' });
}

export function getCountryList(): Promise<ApiResponseCountry> {
  return getRequest(getCountryUrl(), { responseType: 'json' });
}

export function getStateList(selectedCountry: string): Promise<ApiResponseState> {
  // Construct the request body according to the API specifications
  const requestBody = JSON.stringify({ country: selectedCountry });

  return postRequest(getStateUrl(), requestBody, { responseType: 'json' });
}

export function getCitiesList(selectedState: string, selectedCountry: string): Promise<ApiResponseCity> {
  // Construct the request body according to the API specifications
  const requestBody = JSON.stringify({ country: selectedCountry, state: selectedState });

  return postRequest(getCitiesListUrl(), requestBody, { responseType: 'json' });
}

export function getCycleList(patientId: string): Promise<PaginatedResponse<Cycle[]>> {
  return getRequest(getCycleListUrl(patientId), { responseType: 'json' });
}

export function getCycle(id: number): Promise<Cycle> {
  return getRequest(getCycleUrl(id), { responseType: 'json' });
}

export function getCycleReport(id: number): Promise<AxiosResponse> {
  return downloadReport(cycleReportUrl(id));
}

export function createCycle(data: CreateCycle): Promise<CreateCycle> {
  return postRequest(createCycleUrl(), data, { responseType: 'json' });
}

export function updateCycle(id: number, data: Cycle): Promise<Cycle> {
  return putRequest(getCycleUrl(id), data, { responseType: 'json' });
}

export function getEmbryoList(cycleId: number): Promise<PaginatedResponse<Embryo[]>> {
  return getRequest(getEmbryoListUrl(cycleId), { responseType: 'json' });
}

export function deleteEmbryo(id: number): Promise<void> {
  return deleteRequest(deleteEmbryoUrl(id), { responseType: 'json' });
}

export function createTicket(data: TicketFormData): Promise<Ticket> {
  const convertToFormData = (data: TicketFormData): FormData => {
    const formData = new FormData();

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = (data as any)[key];
        formData.append(key, value instanceof File ? value : String(value));
      }
    }

    return formData;
  };
  return postRequest(createTicketUrl(), convertToFormData(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
export async function getTicket(id: number): Promise<Ticket> {
  return await getRequest(getTicketUrl(id), { responseType: 'json' });
}
export function getTicketList(department: string, searchTerm: string): Promise<PaginatedResponse<Ticket[]>> {
  return getRequest(getTicketListUrl(department, searchTerm), { responseType: 'json' });
}

export function createEmbryo(data: EmbryoFormData): Promise<Embryo> {

  const convertToFormData = (data: EmbryoFormData): FormData => {
    const formData = new FormData();

    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const value = (data as any)[key];
        formData.append(key, value instanceof File ? value : String(value));
      }
    }

    return formData;
  };

  return postRequest(getCreateEmbryoUrl(), convertToFormData(data), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function uploadConsentForm(patientId: number, file: File): Promise<void> {
  const convertToFormData = (file: File): FormData => {
    const formData = new FormData();
    formData.append('consent_form_path', file);
    return formData;
  };

  return postRequest(uploadConsentFormUrl(patientId), convertToFormData(file), {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function deleteConsentForm(patientId: number): Promise<void> {
  return postRequest(deleteConsentFormUrl(patientId), {}, { responseType: 'json' });
}
