import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './sidebarComponent.module.css';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ReactComponent as SupportIcon } from '../../assets/Dashboard/icon-support.svg';
import { ReactComponent as ResourcesIcon } from '../../assets/Dashboard/icon-resources.svg';
import { ReactComponent as PatientIcon } from '../../assets/Dashboard/icon-patient.svg';
import { ReactComponent as SupportIconH } from '../../assets/Dashboard/icon-support-hover.svg';
import { ReactComponent as ResourcesIconH } from '../../assets/Dashboard/icon-resources-hover.svg';
import { ReactComponent as PatientIconH } from '../../assets/Dashboard/icon-patient-hover.svg';

interface SidebarItem {
  text: string;
  svg: JSX.Element;  // Regular icon
  svgHover: JSX.Element; // Hover icon
  path: string;
}

const SidebarComponent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hoveredItem, setHoveredItem] = React.useState<string | null>(null);

  const menuItems: SidebarItem[] = [
    { text: 'Patient', svg: <PatientIcon />, svgHover: <PatientIconH />, path: '/patient' },
    { text: 'Resources', svg: <ResourcesIcon />, svgHover: <ResourcesIconH />, path: '/resources' },
    { text: 'Support', svg: <SupportIcon />, svgHover: <SupportIconH />, path: '/support' },
  ];

  const activeItem = menuItems.find(item =>
    location.pathname.startsWith(item.path)
  )?.text || 'Patient';

  const handleMenuClick = (item: SidebarItem) => {
    if (location.pathname !== item.path) {
      navigate(item.path);
    }
  };

  return (
    <Box className={styles.sidebarContainer}>
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.text}>
            <ListItemButton
              className={`${styles.sidebarItemButton} ${activeItem === item.text ? styles.active : ''}`}
              onClick={() => handleMenuClick(item)}
              onMouseEnter={() => setHoveredItem(item.text)}
              onMouseLeave={() => setHoveredItem(null)}
            >
              <ListItemIcon className={styles.menuIcons}>
                {activeItem === item.text || hoveredItem === item.text ? item.svgHover : item.svg}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  style:
                    activeItem === item.text
                      ? { fontWeight: 600, fontSize: 16, whiteSpace: 'nowrap' }
                      : {},
                }}
                className={styles.sidebarLabel}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SidebarComponent;
