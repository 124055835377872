import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Button,
  Chip,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { deleteEmbryo, getEmbryoList } from '../../services/apiService';
import { Embryo } from '../../types';
import { getCycleList } from '../../services/apiService';
import { Cycle } from '../../types';
import { useLoader } from '../loaderProvider/loader';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '../alertProvider';
import styles from './ai.module.css';
import CreateEmbryoForm from './CreateEmbryoForm';
import EmbryoTable from './EmbryoTable';
import ExpandMoreIcon from '../../assets/images/arrowDown.png';
import CalendarIcon from '../../assets/images/calendar.png';
import ReusableCustombtn from '../ResuableCustombtnComponent';
import LOGO21 from '../../assets/research/LOGO21.svg';
import LOGO31 from '../../assets/research/LOGO31.svg';
import LOGO41 from '../../assets/research/LOGO41.svg';
import LOGO51 from '../../assets/research/LOGO51.svg';
import LOGO61 from '../../assets/research/LOGO61.svg';


const EmbryoTableView: React.FC = () => {
  const [embryos, setEmbryos] = useState<Embryo[]>([]);
  const [cycleList, setCycleList] = useState<Cycle[]>([]);
  const [selectedCycle, setSelectedCycle] = useState<Cycle | null>(null);
  const { showLoader, hideLoader } = useLoader();
  const { id } = useParams<{ id: string }>();
  const { addAlert } = useAlert();
  const [error, setError] = useState<string | undefined>(undefined);
  const [openDialog, setOpenDailog] = useState(false);
  const [noCycleDialogOpen, setNoCycleDialogOpen] = useState(false);
  const navigate = useNavigate();

  const fetchCycleList = (patientId: string) => {
    showLoader();
    getCycleList(patientId)
      .then((response) => {
        setCycleList(response.objects);
        // Set the active cycle as the default selected cycle
        const activeCycle = response.objects.find(cycle => cycle.is_active);
        if (activeCycle) {
          setSelectedCycle(activeCycle);
        } else if (response.objects.length === 0) {
          setNoCycleDialogOpen(true);
        }
      })
      .catch((err) => {
        setError('Failed to fetch patients cycle list.');
        addAlert('Failed to fetch patients cycle list.', 'error');
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const fetchEmbryos = (cycleId: number) => {
    showLoader();
    getEmbryoList(cycleId)
      .then((response) => {
        setEmbryos(response.objects);
      })
      .catch((err) => {
        setError('Failed to fetch embryos list.');
        addAlert('Failed to fetch embryos list.', 'error');
        // eslint-disable-next-line no-console
        console.error(err);
      })
      .finally(() => {
        hideLoader();
      });
  };

  useEffect(() => {
    if (selectedCycle) {
      fetchEmbryos(selectedCycle.id);
    }
    // eslint-disable-next-line
  }, [selectedCycle]);

  useEffect(() => {
    if (id) {
      fetchCycleList(id);
    }
    // eslint-disable-next-line
  }, [id]);

  const handleOpenDialog = () => setOpenDailog(true);
  const handleCloseDialog = () => {
    setOpenDailog(false);
    if (selectedCycle) {
      fetchEmbryos(selectedCycle.id);
    }
  };

  const handleCycleChange = (event: SelectChangeEvent<number>) => {
    const selectedId = event.target.value;
    const cycle = cycleList.find((cycle) => cycle.id === selectedId) || null;
    setSelectedCycle(cycle);
  };

  const handleEmbryoDelete = (embryoId: number) => {
    deleteEmbryo(embryoId)
      .then(() => {
        addAlert('Embryo deleted successfully.', 'success');
        setEmbryos((prevEmbryos) => prevEmbryos.filter((embryo) => embryo.id !== embryoId));
      });
  };
  const handleProceedToAddCycle = () => {
    setNoCycleDialogOpen(false);
    navigate(`/patient/${id}#cycleDetails`);
    window.location.reload();
  };

  if (error) {
    return <div>{error}</div>;
  }

  const CustomArrowIcon: React.FC = () => {
    return <img src={ExpandMoreIcon} alt="arrow down" style={{ width: '24px', height: '24px', margin: '0 10px 0 0' }} />;
  };

  return (
    <div className={styles.mainContainer}>
      <Dialog open={noCycleDialogOpen} onClose={() => setNoCycleDialogOpen(false)}>
        <DialogTitle className={styles.noCycleHead}>No cycle details !</DialogTitle>
        <DialogContent className={styles.watermarkContainer}>
          <Typography>It appears that no cycle information is available for this patient.</Typography>
        </DialogContent>
        <DialogActions className={styles.btnsContainer}>
          <Button onClick={handleProceedToAddCycle} className={`button ${styles.proceedBtn}`}>
            Proceed to Add Cycle Details
          </Button>
        </DialogActions>
      </Dialog>
      <div className={styles.testBtns}><div className={styles.buttonContainer}>
        <ReusableCustombtn
          type={['radio']}
          radioOptions={[
            { name: 'AIMBRYO', value: 'AIMBRYO', image: LOGO61, disabled: false },
            { name: 'AISA', value: 'AISA', image: LOGO41, disabled: true },
            { name: 'ERAI', value: 'ERAI', image: LOGO51, disabled: true },
            { name: 'AIOO', value: 'AIOO', image: LOGO31, disabled: true },
            { name: 'ALIKE', value: 'ALIKE', image: LOGO21, disabled: true },
          ]}
        />
      </div>
      </div>

      {/* Cycle Selection Grid */}
      <>
        <Typography variant='h6' gutterBottom>
          Cycle Information
        </Typography>
        <Grid container spacing={3} sx={{ marginBottom: 3 }}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth className={styles.selectFieldInner}>
              <InputLabel id='cycle-select-label' className={styles.textField}>Cycle</InputLabel>
              <Select
                labelId='cycle-select-label'
                id='cycle-select'
                size={'small'}
                value={selectedCycle?.id || ''}
                onChange={handleCycleChange}
                label='Cycle'
                variant='outlined'
                IconComponent={CustomArrowIcon}
                className={styles.selectBox}
              >
                {cycleList.map((cycle) => (
                  <MenuItem key={cycle.id} value={cycle.id}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <span>{cycle.cycle_id}</span>
                      {cycle?.is_active && (
                        <>
                          | <Chip
                            label="Active"
                            color="success"
                            size="medium"
                            style={{ fontWeight: 'bold' }}
                            className={styles.active}
                          />
                        </>
                      )}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Cycle Start Date"
              type="date"
              value={selectedCycle?.start_date || ''}
              disabled
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={CalendarIcon} alt="calendar icon" style={{ width: '24px', height: '24px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Last Menstrual Date"
              type="date"
              value={selectedCycle?.last_menstrual_date || ''}
              disabled
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <img src={CalendarIcon} alt="calendar icon" style={{ width: '24px', height: '24px' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="Retrieval Date"
              type="date"
              value={selectedCycle?.retrieval_date || ''}
              disabled
              size="small"
              variant="outlined"
              InputLabelProps={{ shrink: true }}

            />
          </Grid>
        </Grid>
      </>
      {/* If cycle is selected, then show Embryo List */}
      {selectedCycle && (
        <>
          <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
            <Typography variant="h6" gutterBottom>
              List of Embryos
            </Typography>
            <div className={styles.buttonsContainer}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleOpenDialog}
                className={`${styles.button} ${styles.addRow}`}
              >
                Add Embryo
              </Button>
            </div>
          </Grid>

          <EmbryoTable embryos={embryos} onDelete={handleEmbryoDelete} />

          {/* Modal with form */}
          <CreateEmbryoForm id={selectedCycle.id} open={openDialog} handleClose={handleCloseDialog} />
        </>
      )}
    </div>
  );
};

export default EmbryoTableView;
