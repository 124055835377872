import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Route, createRoutesFromElements, Navigate } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import NoLayout from './layouts/NoLayout';
import DefaultLoader from './components/defaultLoader';
import TabView from './pages/patient/TabView';


// Lazy loading components
const Login = lazy(() => import('./pages/login/index'));
const Dashboard = lazy(() => import('./pages/Dashboard/index'));
const PatientListView = lazy(() => import('./pages/patient/ListPatientView'));
const Resources = lazy(() => import('./pages/Resources/index'));
const Configuration = lazy(() => import('./pages/Configuration/index'));
const Support = lazy(() => import('./pages/Support/index'));
const NewEditPatientView = lazy(() => import('./pages/patient/NewEditPatientView'));
const TicketDetails =lazy(()=>import('./pages/Support/TicketView'));

// Define the routes using createRoutesFromElements
const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Navigate to="/login" replace />} />
      {/* Public Route for Login */}
      <Route
        path="/login"
        element={
          <Suspense fallback={<DefaultLoader />}>
            <NoLayout>
              <Login />
            </NoLayout>
          </Suspense>
        }
      />

      {/* Main Routes with Layout */}
      <Route
        path="/"
        element={<MainLayout />} // MainLayout wraps routes with header and sidebar
      >

        <Route
          index
          path='dashboard'
          element={
            <Suspense fallback={<DefaultLoader />}>
              <Dashboard />
            </Suspense>
          }
          handle={{ breadcrumb: 'Dashboard' }}
        />
        <Route
          path="patient"
          handle={{ breadcrumb: 'Patient' }}
        >
          {/* List all patients */}
          <Route
            index
            element={
              <Suspense fallback={<DefaultLoader />}>
                <PatientListView />
              </Suspense>
            }
            handle={{ breadcrumb: 'Patient List' }}
          />

          {/* View individual patient */}
          <Route
            path=":id"
            element={
              <Suspense fallback={<DefaultLoader />}>
                <TabView isEditMode />
              </Suspense>
            }
            handle={{ breadcrumb: ({ id }: { id: string }) => `Patient #${id}` }}
          />

          {/* Edit individual patient */}
          <Route
            path=":id/edit"
            element={
              <Suspense fallback={<DefaultLoader />}>
                <NewEditPatientView isEditMode />
              </Suspense>
            }
            handle={{ breadcrumb: ({ id }: { id: string }) => `Edit Patient #${id}` }}
          />
          {/* Add a new patient */}
          <Route
            path="new"
            element={
              <Suspense fallback={<DefaultLoader />}>
                <NewEditPatientView isEditMode={false} />
              </Suspense>
            }
            handle={{ breadcrumb: 'New Patient' }}
          />
        </Route>
        <Route
          path='configuration'
          index
          element={
            <Suspense fallback={<DefaultLoader />}>
              <Configuration />
            </Suspense>
          }
          handle={{ breadcrumb: 'Configuration' }}
        />
        <Route
          path='resources'
          index
          element={
            <Suspense fallback={<DefaultLoader />}>
              <Resources />
            </Suspense>
          }
          handle={{ breadcrumb: 'Resources' }}
        />
        <Route
          path='support'
          index
          element={
            <Suspense fallback={<DefaultLoader />}>
              <Support />
            </Suspense>
          }
          handle={{ breadcrumb: 'Support' }}
        />
        <Route path="support">
          <Route path=":id" element={<Suspense fallback={<DefaultLoader />}>
            <TicketDetails />
          </Suspense>} />
        </Route>
      </Route>
    </>,
  )
);

export default routes;
